<template>
  <div>
    <!-- 导航栏 -->
    <div class="newbanner">
      <div class="banner w">
        <div class="logo">
          <img src="../assets/image/logo.png" />
        </div>
        <div class="banner_inner">
          <ul>
            <router-link to="home">
              <li><span>官网首页</span></li>
            </router-link>
            <router-link to="news">
              <li class="active"><span>新闻资讯</span></li>
            </router-link>
            <router-link to="interhealthy">
              <li><span>服务中心</span></li>
            </router-link>
            <router-link to="usercase">
              <li><span>客户案例</span></li>
            </router-link>
            <router-link to="product">
              <li class="serve" @mouseenter="enter" @mouseleave="leave">
                <span>项目中心</span>
                <div :class="active ? 'serve_inner' : 'hide'">
                  <dl>
                    <router-link to="product"><dd>智能硬件</dd></router-link>
                    <router-link to="smartcampus"
                      ><dd>智慧校园</dd></router-link
                    >
                    <router-link to="intelpension"
                      ><dd>智慧养老</dd></router-link
                    >
                    <router-link to=""><dd>智慧医疗</dd></router-link>
                  </dl>
                </div>
              </li>
            </router-link>
            <router-link to="aboutus">
              <li><span>关于我们</span></li>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
    <div class="title w">
      <!-- <el-button type="primary" @click="back">返回</el-button> -->
      <h2>{{ title }}</h2>
      <div class="date w">{{ year + "年" + mouth + "月" + day + "日" }}</div>
      <!-- <h2>可穿戴设备“进军”医疗行业，开启智慧医疗新领域</h2>
      <p>
        随着时代的变迁，技术的创新，医疗行业的各个领域在不断细分，百姓随身携带的智能产品也开始有了“健康意识”
      </p> -->
    </div>
    <!-- <div class="content w">
      <div>资料来源：中国数字医疗网</div>
      <div class="one">
        <span
          >医疗行业是可穿戴设备市场潜力最确定，未来将被“颠覆”得最彻底的领域。随着新技术的创新，医疗行业的各个细分领域，包括诊断、监护、治疗、给药等各个环节都将全面开启智能化。国泰君安研报认为，移动互联网、可穿戴设备、大数据的新技术正在颠覆我们对医疗的认知结构，传统的医疗器械和医院的商业模式或将被全面颠覆，国内大量优秀的企业将全面进军新医疗领域，是未来投资的方向之一。具体内容如下：</span
        >
        <div class="right"></div>
        <span>医疗行业：颠覆进行时</span>
      </div>
      <p>
        “颠覆”正成为今年度实业界和资本市场的关键词，基于移动互联、穿戴式设备、大数据等新一代技术正在快速颠覆各行业的生存业态，在迅猛发展的医疗领域，这些新兴技术与新商业模式的结合正在全面颠覆我们以往对医疗的认知结构，可以预见，医疗的各个细分领域，从诊断、监护、治疗、给药都将全面开启一个智能化的时代，结合商业医疗保险机构，全新的医院、患者、保险的多方共赢商业模式也在探索中爆发，基于医疗大数据平台的诊断与治疗技术也将把个性化医疗推向一个前所未有的空间，传统的医疗器械和医院的商业模式或将被全面颠覆，我们定义为“智慧医疗”。
      </p>
      <p>
        例如，可穿戴医疗设备对血糖、血压、血氧等的监测数据不仅仅可以与智能手机相连，还可借助云存储技术将监测数据通过云端进行存储和分析，并和医院的病例系统和监控中心相连，有异常及时提供预警以及相应的诊治意见。可穿戴医疗作为未来移动互联新的入口，最大的潜力不在于硬件本身，而在于通过硬件粘住客户，在于硬件背后收集到的医疗云端“大数据”，以及由此衍生出的商业模式：利用医疗云端“大数据”为用户提供个性化的远程服务、为企业进行精准的广告投放、为临床外包机构提供研发服务、为医院提供自动分诊服务、为医生提供应用性极强的再教育服务、以及和保险公司合作绑定客户(李克强在2013年8月召开的国务院常务会议明确提出鼓励商业保险机构经办医保服务，可穿戴医疗设备厂商可通过利润分成的模式和保险公司合作获得广大的客户群)。
      </p>
      <p>
        我们预计，国内大量优秀公司将全面进军可穿戴设备、移动医疗、大数据等新医疗领域，我们判断医疗器械企业宝莱特、三诺生物将试水可穿戴医疗设备;我们看到了和佳股份通过收购进军医疗信息化领域;蒙发利从传统按摩器械商到家用健康管理平台的转型;我们也看到了福瑞股份将医疗大数据应用于肝纤维化诊断在国际前沿技术的探索。另外，万达信息在电子病历、医疗信息化的探索将构建一个强大的大数据平台，奥维通信在养老服务新模式的探索值得关注。对于医疗器械上市公司而言，若能借助在可穿戴医疗设备技术的先发优势以及资本优势，成为可穿戴医疗产业链的整合者(如开发移动医疗应用软件、构建医疗监测大数据平台、健康档案区域化联网等)，未来成长空间巨大。 
      </p>
    </div> -->

    <div class="content w" v-html="content"></div>

    <!-- <div class="btn w">
      <button @click="prev">上一篇</button>
      <button @click="next">下一篇</button>
    </div> -->
    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><p>官网首页</p></router-link>
          <router-link to="news"><h3>新闻资讯</h3></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
            <h3>产品中心</h3>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">131-6335-2131</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
          <p style="margin-left: 40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      id: "",
      title: "",
      content: "",
      time: "",
      year: "",
      mouth: "",
      day: "",
      index: 0,
      currPage: 1,
      totalSize: 10,
      active: false,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.index = this.$route.query.index;
    this.currPage = this.$route.query.currPage;
    this.totalSize = this.$route.query.totalSize;
    this.getNewsContent();
    console.log(this.id);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getNewsContent() {
      const { data: res } = await this.$http.get("sys/news/info/" + this.id);
      if (res.success) {
        this.content = Base64.decode(res.data.content);
        this.title = res.data.title;
        var val = res.data.createDate.split("-");
        console.log(val);
        var val1 = val[2].slice(0, 2);
        this.year = val[0];
        this.mouth = val[1];
        this.day = val1;
      } else {
        this.$message.error(res.message);
      }
    },
    async next() {
      const { data: res } = await this.$http.post("sys/news/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != "000000") {
        ths.$message.error(res.message);
      } else {
        if (this.index >= res.data.records.length - 1) {
          this.$message.warning("当前已经是最后一篇");
        } else {
          this.index = parseInt(this.index) + 1;
          this.id = res.data.records[this.index].id;
          // this.total = res.data.total;
          this.getNewsContent();
        }
      }
    },
    async prev() {
      const { data: res } = await this.$http.post("sys/news/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != "000000") {
        ths.$message.error(res.message);
      } else {
        if (this.index <= 0) {
          this.$message.warning("当前已经是第一篇");
        } else {
          this.index = parseInt(this.index) - 1;
          this.id = res.data.records[this.index].id;
          // this.total = res.data.total;
          this.getNewsContent();
        }
      }
    },
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
  },
};
</script>
<style lang='less' scoped>
.newbanner {
  width: 100%;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.08);
}
.title {
  position: relative;
  text-align: center;
  margin: 59px auto 59px;
  .el-button {
    position: absolute;
    left: 0;
    top: -60px;
  }
  h2 {
    // font-size: 38px;
    font-size: 34px;
    color: #606060;
    // margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    color: #345fc7;
  }
}
.content {
  // font-size: 24px;
  color: #707070;
  line-height: 40px;
  margin-bottom: 120px;
  .one {
    margin-top: 15px;
    span {
      display: inline-block;
      width: 709px;
    }
    .right {
      width: 487px;
      height: 293px;
      background: #eeebf8;
      display: inline-block;
    }
  }
  // p {
  //   text-indent: 2rem;
  // }
}
.date {
  font-size: 18px;
  color: #cecece;
  text-align: center;
  margin-top: 20px;
}
.btn {
  // display: flex;
  // justify-content: space-around;
  margin: 79px auto 116px;
  button {
    width: 131px;
    height: 54px;
    border: 1px solid #eeeeee;
    background: none;
    font-size: 28px;
    color: #4b4b4b;
    cursor: pointer;
    margin-left: 276px;
  }
}
</style>
