<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li class="active"><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>

    <div class="swiper animate__backInUp animate__animated">
      <!-- <img src="../assets/image/news.jpg" /> -->
    </div>

    <div class="content">
      <div class="title">
        <div class="title_inner w">
          <router-link to="home">官网首页</router-link
          >&nbsp;&nbsp;&nbsp;>>&nbsp;&nbsp;&nbsp;新闻资讯
        </div>
      </div>
      <div class="details w">
        <div
          class="details_inner animate__fadeInUp wow animate__animated"
          v-for="(item, index) in newsList"
          :key="index"
          @click="godetails(item.id, index)"
        >
          <!-- <div class="inner_left fl"></div> -->
          <div class="inner_left fl" v-html="item.picture">
            <!-- <img v-if="item.picture" :src="item.picture" /> -->
          </div>
          <!-- v-lazy:background-image="item.picture" -->
          <div class="inner_center fl">
            <h2>{{ item.title }}</h2>
            <div class="itemContent" v-html="item.content"></div>
          </div>
          <div class="data fr">{{ item.createDate.slice(0, 10) }}</div>
        </div>
      </div>

      <el-pagination
        layout="prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currPage"
        :page-size="totalSize"
      >
      </el-pagination>
    </div>

    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><p>官网首页</p></router-link>
          <router-link to="news"><h3>新闻资讯</h3></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
            <h3>产品中心</h3>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">137-2672-0950</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
          <p style="margin-left: 40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      newsList: [],
      active: false,
      currPage: 1,
      totalSize: 10,
      total: 0,
    };
  },
  created() {
    this.getnewsList();
  },
  methods: {
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
    async getnewsList() {
      const { data: res } = await this.$http.post("sys/news/list", {
        currPage: this.currPage,
        totalSize: this.totalSize,
      });
      if (res.code != "000000") {
        ths.$message.error(res.message);
      } else {
        res.data.records.map((item, index) => {
          item.content = Base64.decode(item.content);
          // let imgList = [];
          // item.content.replace(
          //   // /<img [^>]*src=['"]([^'"]+)[^>]*>/g,
          //   /<img[^>]+>/g,
          //   (match, capture) => {
          //     imgList.push(capture);
          //   }
          // );
          let imgList = item.content.match(/<img[^>]+>/g)
          // console.log(imgList)
          item.picture = imgList[0];
        });
        this.newsList = res.data.records;
        this.total = res.data.total;
        // console.log(res.data.records);
      }
    },
    handleSizeChange(newSize) {
      this.totalSize = newSize;
      this.getnewsList();
    },
    handleCurrentChange(newPage) {
      this.currPage = newPage;
      this.getnewsList();
    },
    godetails(id, index) {
      // console.log(index);
      this.$router.push({
        path: "/newsdetails",
        query: {
          id: id,
          index: index,
          currPage: this.currPage,
          totalSize: this.totalSize,
        },
      });
    },
  },
};
</script>
<style lang='less' scoped>
.swiper {
  height: 400px;
  background: url("../assets/image/news.jpg") no-repeat top center;
  // img {
  //   height: 400px;
  // }
}
.content {
  padding: 28px 0 116px;
  background: #363636;
  .title {
    height: 51px;
    background: #ddecf3;
    .title_inner {
      font-size: 18px;
      color: #345fc7;
      line-height: 51px;
      a {
        color: #345fc7;
      }
    }
  }
  .details {
    cursor: pointer;
    margin-bottom: 108px;
    .details_inner {
      height: 198px;
      background: #f5f5f5;
      padding: 14px;
      box-sizing: border-box;
      margin-top: 20px;
      .inner_left {
        width: 170px;
        height: 170px;
        background: #ffffff;
        background-repeat: no-repeat;
        background-position: top center;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.07);
        margin-right: 25px;
        /deep/img {
          width: 170px !important;;
          height: 170px !important;;
        }
      }
      .inner_center {
        h2 {
          font-size: 28px;
          font-weight: 400;
          color: #393939;
          margin: 25px 0 22px;
        }
        .itemContent {
          width: 820px;
          height: 65px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          font-size: 20px !important;
          line-height: 36px;
          color: #9e9e9e;
          overflow: hidden;
          // white-space: nowrap;
          text-overflow: ellipsis;
          white-space: nowrap;
          /deep/.MsoNormal {
            font-size: 20px !important;
            text-indent: 0 !important;
            span {
              font-size: 20px !important;
              text-indent: 0 !important;
            }
          }
          p {
            font-size: 20px !important;
          }
        }
      }
      .data {
        margin-top: 32px;
        font-size: 20px;
        color: #9e9e9e;
      }
    }
    .details_inner:hover {
      box-shadow: 3px 3px 19px rgba(0, 0, 0, 0.28);
      .inner_left {
        box-shadow: none;
      }
      .inner_center {
        h2,
        .itemContent {
          color: #345fc7;
        }
      }
      .data {
        color: #9cb3ec;
      }
    }
  }
  /deep/.el-pagination {
    // width: 500px;
    min-width: 500px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  /deep/.el-pagination .btn-prev {
    background: none !important;
    width: 54px;
    height: 54px;
    border: 1px solid #f5f5f5;
    margin-right: 11px;
    .el-icon {
      font-size: 28px;
      color: #fff;
    }
  }
  /deep/.el-pager li {
    background: none;
    width: 54px;
    height: 54px;
    border: 1px solid #f5f5f5;
    font-size: 28px;
    color: #fff;
    font-family: SimHei;
    font-weight: 400;
    line-height: 50px;
    margin: 0 11px;
  }
  /deep/.el-pagination .btn-next {
    background: none;
    width: 54px;
    height: 54px;
    border: 1px solid #f5f5f5;
    margin-left: 11px;
    .el-icon {
      font-size: 28px;
      color: #fff;
    }
  }
  /deep/.el-pager li.active {
    background: #345fc7;
    border: none;
  }
}
</style>
