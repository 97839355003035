<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li class="active"><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>

    <!-- 轮播图 -->
    <div class="wrapper" @mouseenter="Swiperenter" @mouseleave="Swiperleave">
      <swiper :options="swiperOption" ref="mySwiper">
        <!-- slides -->
        <swiper-slide v-for="item in swiperList" :key="item.id">
          <img class="swiper-img" :src="item.url" @click="SwiperGo(item.id)" />
        </swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <!-- 业务范围 -->
    <div class="business animate__backInUp wow animate__animated">
      <div class="business_title">
        <h2>业务范围</h2>
        <p>LINE OF BUSINESS</p>
      </div>
      <div class="business_details">
        <p>
          祥康瑞专注于全行业智慧化落地方案。围绕客户需求，持续创新，根据不同行业及场景，通过软硬件结合的方式，提供定制化解决方案，打造智能生态圈。
        </p>
      </div>
      <div class="business_content w">
        <div
          class="content_inner"
          v-for="(item, index) in businessList"
          :key="index"
          @click="businessGo(index)"
        >
          <div class="top">
            <img v-lazy="require('../assets/image/business.png')" />
            <div class="left">
              <img class="one" v-lazy="item.url" />
              <img class="active" :src="item.acturl" />
            </div>
            <div class="right">
              <h2>{{ item.title }}</h2>
              <p>{{ item.titleValue }}</p>
            </div>
          </div>
          <div class="bottom">
            <img v-lazy="item.bottomUrl" />
            <div class="details" data-wow-iteration="infinite">
              <p>{{ item.details }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <!-- <p class="prev">&lt;</p>
        <p class="next">&gt;</p> -->
      </div>
    </div>

    <!-- 关于我们 -->
    <div
      v-lazy:background-image="require('../assets/image/aboutus.jpg')"
      class="aboutus animate__bounceInLeft wow animate__animated"
      data-wow-delay="1s"
      data-wow-duration="2s"
    >
      <div class="aboutus_title">
        <h2>关于我们</h2>
        <p>ABOUT US</p>
      </div>
      <div class="about_details">
        <p>
          祥康瑞以智能产品为媒介，定制化开发配套软件，通过智能产品的应用与大数据智能化分析，打造智能化生态圈。
        </p>
      </div>
      <div class="about_content w">
        <div class="introduce">
          <div class="top">
            <h3>祥康瑞简介</h3>
          </div>
          <p class="first">
            深圳市祥康瑞智能科技有限公司是一家场景化解决方案提供商，专注于全行业智慧化落地方案。根据各行业不同场景的需求，祥康瑞以智能产品为媒介，定制化开发配套软件，通过智能产品的应用与大数据智能化分析，打造智能化生态圈。
          </p>
          <p>
            目前，祥康瑞拥有自主研发与生产的健康智能手表系列产品，并获得多项专业证书与研发专利。祥康瑞健康校园解决方案被应用于多个校园并取得校方认可。疫情期间，祥康瑞智能手表被应用于深圳政府防疫项目，荣获深圳政府的赞扬与肯定。
          </p>
          <div class="bottom">
            <div class="bo_inner">
              <img v-lazy="require('../assets//image/zhuangye.png')" />
              <p>专业</p>
              <div class="line"></div>
            </div>
            <div class="bo_inner">
              <img v-lazy="require('../assets//image/jingzhun.png')" />
              <p>精准</p>
              <div class="line"></div>
            </div>
            <div class="bo_inner">
              <img v-lazy="require('../assets//image/chuangxin.png')" />
              <p>创新</p>
              <div class="line"></div>
            </div>
            <div class="bo_inner">
              <img v-lazy="require('../assets//image/zhineng.png')" />
              <p>智能</p>
              <!-- <div class="line"></div> -->
            </div>
          </div>
        </div>

        <div class="more">
          <div class="img">
            <img v-lazy="require('../assets/image/us_more.png')" />
          </div>
          <router-link to="aboutus"
            ><div class="more_bot">
              <p>了解更多>></p>
              <span>LEARN MORE</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 新闻资讯 -->
    <div
      class="news animate__rotateIn wow animate__animated"
      data-wow-duration="2s"
    >
      <div class="news_title">
        <h2>新闻资讯</h2>
        <p>NEWS INFORMATION</p>
      </div>
      <div class="news_details">
        <p>
          提供科技行业资讯信息，及时的行业热点新闻及动态趋势，通过该栏目您可以了解到更多全行业前言新闻资讯信息。
        </p>
      </div>
      <div class="news_content">
        <!-- <div
          class="content_inner"
          v-for="(item, index) in newsList"
          :key="index"
          @click="godetails"
        >
          <img v-lazy="item.url" />
          <h2>{{ item.title }}</h2>
          <div class="date">
            <img src="../assets/image/home/icon.png" />
            <span>{{ item.date }}</span>
          </div>
          <p>{{ item.details }}</p>
        </div> -->
        <div
          class="content_inner"
          v-for="(item, index) in newsList"
          :key="index"
          @click="godetails(item.id)"
        >
          <img v-lazy="require('../assets/image/home/news01.png')" />
          <h2>{{ item.title }}</h2>
          <div class="date">
            <img src="../assets/image/home/icon.png" />
            <span>{{ item.createDate }}</span>
          </div>
          <p v-html="item.content"></p>
          <!-- <div v-html="item.content"></div> -->
        </div>
      </div>
    </div>

    <!-- 在线留言 -->
    <div
      class="leavingMes w animate__fadeInDown wow animate__animated"
      data-wow-delay="1s"
    >
      <div class="left">
        <h2>在线留言</h2>
        <p>
          如果你有意向想进一步沟通，请输入您的姓名和联系方式，我们将第一时间向您回电！
        </p>
        <img v-lazy="require('../assets/image/userCase/caselogo.png')" />
      </div>
      <div class="right">
        <div class="ipt">您的姓名： <input v-model="name" /></div>
        <div class="ipt">联系电话： <input v-model="mobile" /></div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入您的留言"
          v-model="LeavMes"
          resize="none"
          clearable
        >
        </el-input>
        <div class="btn" @click="sub">提交留言</div>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><h3>官网首页</h3></router-link>
          <router-link to="news"><p>新闻资讯</p></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
            <h3>产品中心</h3>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">131-6335-2131</p>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
          <p style="margin-left: 40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      name: "",
      mobile: "",
      LeavMes: "",
      active: false,
      phone: ["8000-0000-0000", "8000-0000-0000"],
      swiperOption: {
        loop: true, //循环轮播
        autoplay: false,
        // effect: "coverflow",
        pagination: { el: ".swiper-pagination", clickable: true },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperList: [
        {
          id: "001",
          url: require("../assets/image/home/banner01.png"),
        },
        {
          id: "002",
          url: require("../assets/image/home/banner02.png"),
        },
        {
          id: "003",
          url: require("../assets/image/home/banner03.png"),
        },
      ],
      businessList: [
        {
          url: require("../assets/image/home/b1.png"),
          acturl: require("../assets/image/home/a1.png"),
          title: "智能硬件",
          titleValue: "配套硬件设备，实现数据追踪",
          details:
            "将传统设备注入“思想”，通过软硬件结合的方式，让硬件与软件实现信息同步",
          bottomUrl: require("../assets/image/home/c1.png"),
        },
        {
          url: require("../assets/image/home/b2.png"),
          acturl: require("../assets/image/home/a2.png"),
          title: "智慧校园",
          titleValue: "大数据分析辅助优化教学方案",
          details: "将校园体测设备与软件联通，实现校园健康管理效率化可视化落地",
          bottomUrl: require("../assets/image/home/c2.png"),
        },
        {
          url: require("../assets/image/home/b3.png"),
          acturl: require("../assets/image/home/a3.png"),
          title: "智慧医疗",
          titleValue: "有效利用空间，提高服务效率",
          details: "传统医疗模式改革升级，实现医患高效问诊，整顿空间管理",
          bottomUrl: require("../assets/image/home/c3.png"),
        },
        {
          url: require("../assets/image/home/b4.png"),
          acturl: require("../assets/image/home/a4.png"),
          title: "智慧养老",
          titleValue: "疾病风险预警，掌握健康趋势",
          details:
            "实时追踪用户健康情况，并且可以通过趋势观察，做到早知道早预防",
          bottomUrl: require("../assets/image/home/c4.png"),
        },
      ],
      newsList: [],
    };
  },
  created() {
    this.getnewsList();
  },
  methods: {
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
    SwiperGo(id) {
      if (id == "001") {
        this.$router.push("/smartcampus");
      } else if (id == "002" || id == "003") {
        this.$router.push("/interhealthy");
      }
    },
    Swiperenter() {
      this.$refs.mySwiper.swiper.autoplay.stop();
      // this.mySwiper.autoplay.stop();
      // console.log(this.$refs.mySwiper.swiper.autoplay.stop());
      // console.log(this.mySwiper);
    },
    Swiperleave() {
      this.mySwiper.autoplay.start();
    },
    businessGo(id) {
      if (id == 0) {
        this.$router.push("product");
      } else if (id == 1) {
        this.$router.push("smartcampus");
      } else if (id == 3) {
        this.$router.push("intelpension");
      }
    },
    async sub() {
      const reg = /^1([38]\d|5[0-35-9]|7[3678])\d{8}$/;
      if (!this.name) {
        this.$message.warning("请填写您的姓名");
      } else if (!this.mobile) {
        this.$message.warning("请填写您的联系电话");
      } else if (!reg.test(this.mobile)) {
        this.$message.warning("请输入正确的手机号码");
      } else {
        const { data: res } = await this.$http.post("sys/leave/save", {
          userName: this.name,
          mobile: this.mobile,
          content: this.LeavMes,
        });
        if (res.code != "000000") {
          this.$message.error(res.message);
        } else {
          this.$message.success("您的留言提交成功，感谢您的留言");
          this.name = "";
          this.mobile = "";
          this.LeavMes = "";
        }
      }
    },
    godetails(id) {
      this.$router.push({
        path: "/newsdetails",
        query: {
          id: id,
        },
      });
    },
    // 获取新闻
    async getnewsList() {
      const { data: res } = await this.$http.post("sys/news/list", {
        currPage: 1,
        totalSize: 3,
      });
      if (res.code != "000000") {
        ths.$message.error(res.message);
      } else {
        res.data.records.map((item, index) => {
          item.content = Base64.decode(item.content);
          item.createDate = item.createDate.slice(0, 10);
          // item.createDate.slice(0, 4) +
          // "年" +
          // item.createDate.slice(5, 2) +
          // "月" +
          // item.createDate.slice(8, 2) +
          // "日";
        });
        this.newsList = res.data.records;
        this.total = res.data.total;
        // console.log(res.data.records);
      }
    },
  },
  computed: {
    mySwiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
};
</script>

<style lang="less" scoped>
.hide {
  display: none;
}
.head {
  width: 100%;
  background: #252c3b;
  height: 40px;
  color: #fff;
  font-size: 14px;
  .head_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .phone {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
    i {
      width: 26px;
      height: 26px;
      display: inline-block;
      background: #fff;
      // margin-top: 7px;
    }
    .login {
      span {
        display: inline-block;
        width: 2px;
        height: 20px;
        background: #fff;
        margin: 0 20px;
        vertical-align: middle;
      }
    }
    a {
      color: #fff;
    }
  }
}

.swiper-img {
  width: 100%;
  height: 100%;
}
.wrapper {
  overflow: hidden;
  width: 100%;
  // height: 69vh;
  height: 720px;
  .swiper_one {
    position: relative;
    p {
      position: absolute;
      font-size: 32px;
      color: #eee;
      bottom: 25%;
      left: 50%;
      transform: translateX(-50%);
      letter-spacing: 15px;
    }
  }

  .swiper-container {
    width: 100%;
    height: 720px;
    // height: 69vh;
    .swiper-button-next {
      right: 25px;
    }
  }
  // padding-bottom: 30%; /*相对于宽高比自动撑开 */
  /* width:100%;
    height: 31.25vw; 除了上面那种方法，也可以这么写，意思是宽高比例*/
}
.business {
  height: 815px;
  background: #f1f6ff;
  // margin: 55px auto 0;
  padding-top: 95px;
  box-sizing: border-box;
  .business_title {
    margin: 0 auto;
    text-align: center;
    position: relative;
    h2 {
      font-size: 28px;
      color: #3d66c8;
      font-weight: 500;
      width: 135px;
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -62px;
      background: #f1f6ff;
    }
    p {
      font-size: 16px;
      color: #bcbcbc;
      width: 315px;
      height: 80px;
      font-family: "Arial";
      border: 1px solid #dcdcdc;
      line-height: 80px;
      margin: -19px auto 0;
      letter-spacing: 2px;
    }
  }
  .business_details {
    width: 784px;
    font-size: 16px;
    font-family: PingFangSC-Regular, sans-serif;
    color: #bcbcbc;
    text-align: center;
    margin: 28px auto 92px;
    line-height: 25px;
  }
  .business_content {
    display: flex;
    justify-content: space-between;
    .content_inner {
      cursor: pointer;
      width: 300px;
      .top {
        width: 100%;
        height: 99px;
        box-sizing: border-box;
        border-right: 1px solid #ddd;
        display: flex;
        // justify-content: flex-start;
        position: relative;
        z-index: 999;
        img {
          width: 100%;
          height: 111px;
          // z-index: 999;
        }
        .left {
          position: absolute;
          left: 20px;
          top: 25px;
          width: 55px;
          height: 55px;
          // background: #f1f6ff;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
          }
          .active {
            display: none;
          }
        }
        .right {
          position: absolute;
          top: 23px;
          left: 87px;
          h2 {
            font-size: 24px;
            color: #000;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            color: #b4b4b4;
          }
        }
      }
      .bottom {
        width: 300px;
        height: 176px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .details {
          // overflow: hidden;
          display: none;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.56);
          // background: #000;
          // background:#df350bfa;
          // opacity: 0.56;
          // animation: myfirst 1s;
          // -webkit-animation: myfirst 1s;
          // @keyframes myfirst {
          //   from {
          //     top: 80px;
          //   }
          //   to {
          //     top: 0;
          //   }
          // }
          p {
            width: 210px;
            color: #fff;
            font-size: 14px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            left: 50%;
          }
        }
      }
    }
    .content_inner:hover {
      .top {
        .left {
          .one {
            display: none;
          }
          .active {
            display: block;
          }
        }
      }
      .bottom {
        .details {
          display: block;
        }
      }
    }
  }
  .page {
    text-align: center;
    font-size: 24px;
    margin-top: 75px;
    .prev,
    .next {
      width: 38px;
      height: 38px;
      background: #ffffff;
      color: #345fc7;
      line-height: 38px;
      display: inline-block;
      margin-right: 3px;
      cursor: pointer;
    }
    .prev:hover,
    .next:hover {
      background: #345fc7;
      color: #fff;
    }
  }
}

.aboutus {
  margin-bottom: 145px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  // background: url(../assets/image/aboutus.jpg) no-repeat top center;
  padding-top: 60px;
  box-sizing: border-box;
  .aboutus_title {
    margin: 60px auto 0;
    text-align: center;
    position: relative;
    h2 {
      font-size: 28px;
      color: #fff;
      font-weight: 500;
      width: 135px;
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -62px;
      background: rgba(0, 0, 0, 11);
    }
    p {
      font-size: 16px;
      color: #fff;
      width: 315px;
      height: 80px;
      font-family: "Arial";
      border: 1px solid #fff;
      line-height: 80px;
      margin: -19px auto 0;
      letter-spacing: 2px;
      z-index: -1;
    }
  }
  .about_details {
    width: 842px;
    font-size: 12px;
    font-family: PingFangSC-Regular, sans-serif;
    color: #fff;
    text-align: center;
    margin: 26px auto 60px;
    line-height: 25px;
  }
  .about_content {
    display: flex;
    justify-content: space-between;
    .introduce {
      width: 748px;
      height: 594px;
      box-sizing: border-box;
      padding: 76px 33px 0;
      background: #fff;
      box-shadow: 2px 3px 6px rgba(5, 5, 5, 0.12);
      .top {
        border-bottom: 1px solid #bcbcbc;
        margin-bottom: 40px;
        h3 {
          width: 123px;
          font-size: 24px;
          padding-bottom: 4px;
          border-bottom: 2px solid #345fc7;
          margin-bottom: -1px;
        }
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, sans-serif;
        color: #666;
        text-indent: 2em;
        line-height: 30px;
        letter-spacing: 1px;
      }
      .first {
        margin-bottom: 20px;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 670px;
        overflow: hidden;
        .bo_inner {
          width: 167px;
          height: 117px;
          text-align: center;
          position: relative;
          img {
            transform: scale(0.36);
            position: absolute;
            top: -20px;
            left: 13%;
          }
          p {
            font-size: 16px;
            color: #2d2d2d;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 24%;
          }
          .line {
            width: 1px;
            height: 70px;
            border-right: 1px dashed #d5d5d5;
            position: absolute;
            right: 0;
            top: 32px;
          }
        }
      }
    }

    .more {
      width: 435px;
      height: 594px;
      position: relative;
      .img {
        width: 435px;
        height: 556px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .more_bot {
        width: 100%;
        height: 74px;
        background: #345fc7;
        text-align: center;
        font-size: 16px;
        padding-top: 15px;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;
        p {
          color: #fff;
          font-family: PingFangSC-Regular, sans-serif;
        }
        span {
          color: #cecdcd;
          font-family: Arial;
        }
      }
    }
  }
}
.news {
  margin-bottom: 157px;
  .news_title {
    margin: 0 auto;
    text-align: center;
    position: relative;
    h2 {
      font-size: 28px;
      color: #3d66c8;
      font-weight: 500;
      width: 135px;
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -62px;
      background: #fff;
    }
    p {
      font-size: 16px;
      color: #bcbcbc;
      width: 315px;
      height: 80px;
      font-family: "Arial";
      border: 1px solid #dcdcdc;
      line-height: 80px;
      margin: -19px auto 0;
      letter-spacing: 2px;
    }
  }
  .news_details {
    width: 784px;
    font-size: 16px;
    font-family: PingFangSC-Regular, sans-serif;
    color: #bcbcbc;
    text-align: center;
    margin: 28px auto 92px;
    line-height: 25px;
  }
  .news_content {
    width: 1350px;
    margin: 83px auto 0;
    display: flex;
    justify-content: flex-start;
    // justify-content: space-between;
    .content_inner {
      width: 430px;
      height: 353px;
      padding: 12px;
      background: #f7f9ff;
      box-sizing: border-box;
      cursor: pointer;
      margin-left: 30px;
      img {
        width: 406px;
        height: 186px;
      }
      h2 {
        font-size: 18px;
        color: #242424;
        margin: 15px 0;
      }
      .date {
        margin-bottom: 30px;
        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 8px;
        }
        span {
          // display: inline-block;
          font-size: 16px;
          color: #bebebe;
          // vertical-align: middle;
        }
      }
      p {
        height: 40px;
        font-size: 14px;
        color: #bebebe;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 20px;
        /deep/.MsoNormal {
          text-indent: 0 !important;
          span {
            text-indent: 0 !important;
          }
        }
      }
    }
    .content_inner:nth-of-type(1) {
      margin-left: 0;
    }
    .content_inner:hover {
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
      h2 {
        color: #345fc7;
      }
      p {
        color: #345fc7;
      }
      .date {
        span {
          color: #345fc7;
        }
      }
    }
  }
}
.leavingMes {
  height: 605px;
  position: relative;
  .left {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    font-family: PingFang SC;
    font-size: 14px;
    color: #646464;
    img {
      width: 674px;
      height: 399px;
    }
    h2 {
      // font-size: 24px;
      font-size: 42px;
      font-weight: bold;
      color: #2a2a2a;
    }
    p {
      width: 520px;
      line-height: 36px;
      margin: 43px 0 40px;
      font-size: 20px;
      color: #a8a8a8;
    }
  }
  .right {
    position: absolute;
    right: 0;
    bottom: 10px;
    // width: 50%;
    // height: 574px;
    .ipt {
      width: 450px;
      height: 43px;
      line-height: 43px;
      margin-top: 60px;
      color: #262626;
      // border-bottom: 1px solid #727272;
      input {
        width: 330px;
        border: none;
        outline: none;
        border-bottom: 1px solid #333;
        padding: 5px;
        font-size: 16px;
        color: #262626;
      }
    }
    .el-textarea {
      margin-top: 81px;
      width: 450px;
      font-size: 18px;
    }
    .btn {
      margin-top: 71px;
      // width: 140px;
      // height: 60px;
      width: 140px;
      height: 50px;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
      background: #345fc7;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      color: #fff;
      border-radius: 8px;
      cursor: pointer;
      margin-left: 300px;
    }
  }
}
// 媒体查询自适应
@media screen and (max-width: 1680px) {
  .wrapper {
    height: 620px;
    .swiper-container {
      height: 620px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .wrapper {
    height: 520px;
    .swiper-container {
      height: 520px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .wrapper {
    height: 520px;
    .swiper-container {
      height: 520px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .wrapper {
    height: 480px;
    .swiper-container {
      height: 480px;
    }
  }
}
</style>
