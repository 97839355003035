import Vue from 'vue'
import { Button, Carousel, CarouselItem, Pagination, Input, Message,Tooltip  } from 'element-ui'

Vue.prototype.$message = Message;
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Tooltip)
