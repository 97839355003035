<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li class="active"><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="swiper">
      <!-- <img src="../assets//image/about_banner.png" /> -->
      <div class="inner">
        <h2>关于我们</h2>
        <p>ABOUT US</p>
        <img v-lazy="require('../assets/image/arrowwhilt.png')" />
      </div>
    </div>
    <div class="introduce w animate__fadeInDown wow animate__animated">
      <div class="left">
        <h2>COMPANY PROFILE<span></span></h2>
        <h3>品牌介绍</h3>
        <p>
          深圳市祥康瑞智能科技有限公司是一家场景化解决方案提供商。专注于全行业智慧化落地方案。根据各行业不同场景的需求，祥康瑞以智能产品为媒介，定制化开发配套软件，通过智能产品的应用与大数据智能化分析，打造智能化生态圈。
        </p>
        <p>
          目前，祥康瑞拥有自主研发与生产的健康智能手表系列产品，并获得多项专业证书与研发专利。祥康瑞健康校园解决方案被应用于多个校园并取得校方认可。疫情期间，祥康瑞智能手表被应用于深圳政府防疫项目，荣获深圳政府的赞扬与肯定。
        </p>
      </div>
      <div class="right">
        <div class="inner fr">
          <img v-lazy="require('../assets/image/userCase/caselogo.png')" />
        </div>
      </div>
    </div>
    <div class="show w " data-wow-delay="1s">
      <div class="left animate__fadeInLeft wow animate__animated">
        <div
          :class="['inner', showIndex == index ? 'innerActive' : '']"
          v-for="(item, index) in showleft"
          :key="index"
          @click="showChange(index)"
        >
          <p>{{ item }}</p>
          <img v-lazy="require('../assets/image/aboutus02.png')" />
        </div>
        <!-- <div class="inner inner02"><p>有序的陈列管理</p></div>
        <div class="inner inner03"><p>车间流水线</p></div> -->
      </div>
      <div
        v-for="(item, index) in showRight"
        :key="index"
        :class="showIndex == index ? 'right' : 'rightnone'"
      >
        <div class="bck animate__fadeInRight wow animate__animated">
          <el-carousel
            trigger="hover"
            @change="showRightchange($event,index)"
            indicator-position="none"
            arrow="never"
          >
            <el-carousel-item
              v-for="(item1, index1) in item.inner"
              :key="index1"
            >
              <img v-lazy="item1.url" />

            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- <img class="bck" src="../assets/image/us01.png" /> -->
        <img
          :class="['pic01', showIndexList[index] == 0 ? 'active' : '']"
          v-lazy="item.url1"
        />
        <img
          :class="['pic02', showIndexList[index] == 1 ? 'active' : '']"
          v-lazy="item.url2"
        />
      </div>
    </div>

    <div class="contact">
      <img src="../assets/image/contact.png" alt="" />
      <div class="inner w animate__fadeInTopRight animate__animated wow">
        <div class="details">
          <h2>CONTACT US</h2>
          <h3>联系我们</h3>
          <p>深圳市祥康瑞智能科技有限公司</p>
          <p>地址：深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B</p>
          <!-- <p>电话：xxx</p> -->
          <p>手机：13163352131</p>
          <p>邮箱：13163352131@163.com</p>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><p>官网首页</p></router-link>
          <router-link to="news"><p>新闻资讯</p></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><h3>关于我们</h3></router-link>
        </div>
        <div class="two">
          <router-link to="product">
          <h3>产品中心</h3>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">131-6335-2131</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
        <p style="margin-left:40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showRightId: 0,
      showIndex: 0,
      showIndexList:[0,0,0],
      CarouRight: true,
      active: false,
      showleft: ["海内外多项专利", "有序的陈列管理", "车间流水线"],
      showRight: [
        {
          url1: require("../assets/image/aboutus/us03.png"),
          url2: require("../assets/image/aboutus/us33.png"),
          inner: [
            { url: require("../assets/image/aboutus/us03.png") },
            { url: require("../assets/image/aboutus/us33.png") },
          ],
        },
        {
          url1: require("../assets/image/aboutus/us02.png"),
          url2: require("../assets/image/aboutus/us22.png"),
          inner: [
            { url: require("../assets/image/aboutus/us02.png") },
            { url: require("../assets/image/aboutus/us22.png") },
          ],
        },
        {
          url1: require("../assets/image/aboutus/us01.png"),
          url2: require("../assets/image/aboutus/us11.png"),
          inner: [
            { url: require("../assets/image/aboutus/us01.png") },
            { url: require("../assets/image/aboutus/us11.png") },
          ],
        },
      ],
    };
  },
  methods: {
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
    showChange(id) {
      this.showIndex = id;
    },
    showRightchange(val,index) {
        // this.showIndexList[val] = index
        // this.showIndexList[index] = val
        this.$set( this.showIndexList,index,val)
        // console.log('SUOYIN：',index,'外层：',val, this.showIndexList)

    },
  },
};
</script>
<style lang='less' scoped>
.swiper {
  position: relative;
  height: 400px;
  background: url('../assets/image/about_banner.png') top center no-repeat;
  img {
    width: 100%;
    height: 400px;
  }
  .inner {
    color: #fff;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 22px;
    h2 {
      font-weight: 400;
      font-size: 27px;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      margin: 5px 0 35px;
    }
    img {
      width: 21px;
      height: 25px;
    }
  }
}
.hide {
  display: none;
}
.introduce {
  display: flex;
  justify-content: space-between;
  margin-top: 95px;
  .left {
    z-index: 20;
    padding-top: 34px;
    h2 {
      font-size: 35px;
      font-family: Arial;
      font-weight: bold;
      color: #436acb;
      span {
        display: inline-block;
        width: 188px;
        height: 1px;
        background: #345fc7;
        transform: translateY(-13px);
        margin-left: 29px;
      }
    }
    h3 {
      font-size: 28px;
      color: #436acb;
      margin: 30px 0 55px;
    }
    p {
      font-size: 20px;
      text-indent: 2em;
      margin-top: 30px;
      width: 598px;
      line-height: 36px;
      color: #464646;
    }
  }
  .right {
    width: 784px;
    height: 569px;
    background: #f9f9f9;
    margin-left: -163px;
    .inner {
      width: 555px;
      height: 352px;
      // background: #d1dfe2;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.show {
  margin: 181px auto 110px;
  display: flex;
  justify-content: space-between;
  .left {
    .inner {
      width: 367px;
      height: 144px;
      background: #efefef;
      // color: #fff;
      // color: #436ACB;
      text-align: center;
      // line-height: 144px;
      font-size: 28px;
      position: relative;
      // z-index: 13;
      cursor: pointer;
      p {
        width: 196px;
        height: 144px;
        line-height: 144px;
        color: #436acb;
        // color: #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;
      }
      img {
        width: 425px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 12;
        display: none;
      }
    }
    .innerActive {
      p {
        color: #fff;
      }
      img {
        display: block;
      }
    }
    .inner:nth-of-type(1) {
      background: #dbdbdb;
    }
    .inner:nth-of-type(2) {
      background: #efefef;
    }
    .inner:nth-of-type(3) {
      background: #f8f8f8;
    }
  }
  .right {
    position: relative;
    .bck {
      width: 833px;
      height: 434px;
      img {
        width: 100%;
        height: 100%;
      }
      /deep/.el-carousel__container {
        height: 434px;
      }
    }
    .pic01,
    .pic02 {
      width: 208px;
      height: 108px;
      position: absolute;
      bottom: -60px;
      z-index: 99;
    }
    .pic01 {
      left: 143px;
      // border: 3px solid #436acb;
      // box-shadow: 3px 3px 2px rgba(8, 57, 220, 0.38);
    }
    .pic02 {
      right: 139px;
    }
    .active {
      border: 3px solid #436acb;
      box-shadow: 3px 3px 2px rgba(8, 57, 220, 0.38);
    }
  }
  .rightnone {
    display: none;
  }
}
.contact {
  position: relative;
  height: 1602px;
  img {
    width: 100%;
    height: 1602px;
  }
  .inner {
    position: absolute;
    top: 249px;
    left: 19.2%;
    .details {
      width: 814px;
      height: 436px;
      background: rgba(99, 99, 99, 0.56);
      //   opacity: 0.56;
      padding-left: 31px;
      box-sizing: border-box;
      padding-top: -20px !important;
      h2 {
        font-size: 108px;
        font-family: Arial;
        opacity: 0.06;
        color: #fff;
        // margin-top: -27px;
        position: relative;
        top: -25px;
      }
      h3 {
        font-size: 46px;
        color: #436acb;
        margin-bottom: 35px;
        margin-top: -13px;
      }
      p {
        font-size: 24px;
        line-height: 44px;
        color: #ffffff;
      }
    }
  }
}
</style>
