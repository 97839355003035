<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve active" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="swiper animate__backInUp animate__animated">
      <!-- <img src="../assets/image/IntelPension/banner.png" /> -->
      <div class="inner">
        <h2>智慧养老</h2>
        <p>THE AGED</p>
        <img src="../assets/image/arrowblack.png" />
      </div>
      <div class="content w">
        <h2>互联网+健康管理</h2>
        <p>祥康瑞智能手表，采用传统中医诊脉</p>
        <p>与当代人工智能技术相结合,</p>
        <p>每半小时系统自动采集用户的心率、血压、血氧等体征信息，</p>
        <p>并将报告上传到APP，传达给家人和医生。</p>
      </div>
    </div>
    <!-- 健康管家 -->
    <div class="health w">
      <!-- <div class="health_title">
        <h2>健康管家</h2>
        <p>HEALTH BUTLER</p>
      </div>
      <div class="health_details">
        <p>
          7X12小时健康管家监测老年人心理健康、家庭适老化改造、老年人传染病预防、老年人艾滋病预防、老年人安全用药、失能老人的家庭照护、公众生命教育、中医药和老年健康、数字技术应用与老年健康等12个系列。
        </p>
      </div> -->
      <div class="health_content w">
        <div
          class="content_inner"
          v-for="(item, index) in healthList"
          :key="index"
        >
          <div class="img">
            <img v-lazy="item.img" />
          </div>
          <h2>{{ item.title }}</h2>
          <p>{{ item.details }}</p>
          <!-- <a href="#">查看更多>></a> -->
        </div>
      </div>
    </div>
    <!-- 智能手表适用人群 -->
    <div class="applicable w">
      <div class="title">
        <h2>祥康瑞智能手表适用人群</h2>
        <p>SUITABLE FOR THE CROWD</p>
        <!-- <img class="left" src="../assets/interHealth/left.png" />
        <img class="right" src="../assets/interHealth/right.png" /> -->
        <div class="line"></div>
      </div>
      <div class="details">
        <div class="details_top animate__fadeInUp wow animate__animated">
          <img v-lazy="require('../assets/image/IntelPension/01.png')" />
        </div>
        <div class="details_bottom1 ">
          <div class="bottom_inner animate__fadeInLeft wow animate__animated">
            <img v-lazy="require('../assets/image/IntelPension/02.png')" />
          </div>
          <div class="bottom_inner animate__fadeInRight wow animate__animated">
            <img v-lazy="require('../assets/image/IntelPension/03.png')" />
          </div>
        </div>
        <div class="details_bottom2">
          <div class="bottom_inner animate__fadeInLeft wow animate__animated">
            <img v-lazy="require('../assets/image/IntelPension/04.png')" />
          </div>
          <div class="bottom_inner animate__fadeInRight wow animate__animated">
            <img v-lazy="require('../assets/image/IntelPension/05.png')" />
          </div>
        </div>
      </div>
    </div>
    <!-- 服务中心 -->
    <div class="serve animate__fadeInLeft wow animate__animated">
      <!-- <div class="serve_title">
        <h2>服务中心</h2>
        <p>SERVICE CENTER</p>
      </div> -->
      <!-- <div class="serve_details">
        <p>
          7X12小时健康管家监测老年人心理健康、家庭适老化改造、老年人传染病预防、老年人艾滋病预防、老年人安全用药、失能老人的家庭照护、公众生命教育、中医药和老年健康、数字技术应用与老年健康等12个系列。
        </p>
      </div> -->
      <div class="serve_content">
        <div class="content_top">
          <ul>
            <li
              :class="serveIndex == index ? 'serveActive' : ''"
              v-for="(item, index) in serveList"
              :key="item.id"
              @click="serveChangeIndex(index)"
            >
              {{ item.title }}
              <p></p>
            </li>
          </ul>
        </div>

        <el-carousel
          :interval="4000"
          type="card"
          indicator-position="none"
          :autoplay="false"
          ref="serveRef"
          @change="servechangeData"
        >
          <el-carousel-item v-for="item in serveList" :key="item.index">
            <div class="content_inner">
              <div class="inner_left">
                <img v-lazy="item.url" />
              </div>

              <div class="inner_right">
                <h2>{{ item.title }}</h2>
                <p>{{item.content}}</p>
                <!-- <a>查看更多>></a> -->
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><h3>官网首页</h3></router-link>
          <router-link to="news"><p>新闻资讯</p></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
          <p>产品中心</p>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><h3>智慧养老</h3></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">137-2672-0950</p>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
        <p style="margin-left:40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
import watch from "../assets/image/watch.png";
import user from "../assets/image/user.png";
import doctor from "../assets/image/doctor.png";
import serve from "../assets/image/serve.png";
import risk from "../assets/image/risk.png";
export default {
  data() {
    return {
      active: false,
      // 健康管家列表
      healthList: [
        {
          img: watch,
          title: "智能健康手表",
          details: "贴身管家 健康守护",
        },
        {
          img: user,
          title: "APP用户版",
          details: "远程监护 时刻陪伴",
        },
        {
          img: doctor,
          title: "APP医生版",
          details: "医生在线 实时观察",
        },
        {
          img: serve,
          title: "专属健康服务",
          details: "个性分析 专属推送",
        },
        {
          img: risk,
          title: "健康风险预警",
          details: "异常预警 防范未然",
        },
      ],
      // 服务中心列表
      serveList: [
        {
          id: "01",
          title: "互联网+健康管理",
          url: require("../assets/image/serve01.png"),
          content:'中医诊脉与人工智能技术相结合，自动采集用户的体征信息，并形成健康报告上传至APP，通过健康饮食、科学作息与安全预警，实现智慧化健康管理'
        },
        {
          id: "02",
          title: "远程守护",
          url: require("../assets/image/serve01.png"),
          content:'智能手表佩戴者信息采集同步亲朋好友APP，如有特殊情况，系统会生成预警，达到早知道早预防的效果，做贴近用户的健康管家'
        },
        {
          id: "03",
          title: "实时监测",
          url: require("../assets/image/IntelPension/doctor.png"),
          content:'佩戴者可通过血压、血氧、血糖、心理测试，实时观察身体健康情况，如有突发情况，可以GPS定位，SOS紧急求助，避免意外发生错过最佳治疗时机'
        },
        {
          id: "04",
          title: "在线医生",
          url: require("../assets/image/IntelPension/doctor.png"),
          content:'通过邀请关注，医生可获取用户基本信息和健康报告，在不打扰用户的情况下，了解客户的健康情况，提升整体的问诊效率及医疗效果'
        },
      ],
      // 服务列表默认显示
      serveIndex: 0,
    };
  },
  methods: {
    serveChangeIndex(id) {
      this.serveIndex = id;
      this.$refs.serveRef.setActiveItem(id);
    },
    servechangeData(val) {
      console.log(val);
      this.serveIndex = val;
    },
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
  },
};
</script>
<style lang='less' scoped>
.swiper {
  height: 400px;
  position: relative;
  padding-top: 121px;
  box-sizing: border-box;
  background: url('../assets/image/IntelPension/banner.png') top center no-repeat;
  .inner {
    color: #000;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 17px;
    h2 {
      font-weight: 400;
      font-size: 27px;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      margin: 5px 0 35px;
    }
    img {
      width: 21px;
      height: 25px;
    }
  }
  .content{
    // position: absolute;
    // margin-top: 121px;
    // left: 18.2%;
    h2{
      font-size: 28px;
      color: #345FC7;
      margin-bottom: 30px;
    }
    p{
      font-size: 18px;
      color: #A0AFE9;
      line-height: 34px;
    }
  }
  img {
    width: 100%;
    height: 400px;
  }
}
.health {
  //   margin: 100px auto 106px;
  //   .health_title {
  //     margin: 0 auto;
  //     text-align: center;
  //     position: relative;
  //     h2 {
  //       font-size: 28px;
  //       color: #345fc7;
  //       font-weight: 500;
  //       width: 135px;
  //       position: absolute;
  //       top: -20px;
  //       left: 50%;
  //       margin-left: -62px;
  //       background: #fff;
  //     }
  //     p {
  //       font-size: 16px;
  //       color: #999;
  //       width: 315px;
  //       height: 80px;
  //       font-family: "Arial";
  //       border: 1px solid #dcdcdc;
  //       line-height: 80px;
  //       margin: -19px auto 0;
  //       letter-spacing: 2px;
  //     }
  //   }
  //   .health_details {
  //     width: 842px;
  //     font-size: 12px;
  //     font-family: PingFangSC-Regular, sans-serif;
  //     color: #8a8a8a;
  //     text-align: center;
  //     margin: 26px auto 100px;
  //     line-height: 25px;
  //   }
  .health_content {
    margin: 145px auto 165px;
    display: flex;
    justify-content: space-between;
    .content_inner {
      width: 235px;
      text-align: center;
      height: 261px;
      .img {
        margin-top: 32px;
        width: 235px;
        height: 116px;
        img {
          transform: scale(0.5);
        }
      }
      h2 {
        font-size: 16px;
        color: #343434;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
        color: #a4a4a4;
        margin-bottom: 14px;
      }
      a {
        font-size: 10px;
        color: #929292;
      }
    }
    .content_inner:nth-child(odd) {
      background: #f3f3f3;
    }
    .content_inner:hover {
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.12);
      a {
        color: #345fc7;
      }
    }
  }
}

.applicable {
  margin: 100px auto 223px;
  .title {
    //   height: 55px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    width: 408px;
    h2 {
      font-size: 28px;
      font-weight: 400;
      color: #2a2a2a;
    }
    p {
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #cbcbcb;
      //   margin-top: -1px;
    }
    //   .left {
    //     position: absolute;
    //     left: 0;
    //     top: 50%;
    //   }
    //   .right {
    //     position: absolute;
    //     right: 0;
    //     top: 50%;
    //   }
    .line {
      width: 47px;
      height: 3px;
      background: #345fc7;
      margin: 34px auto 0;
    }
  }
  .details {
    width: 1206px;
    margin: 113px auto 0;
    img {
      width: 100%;
      height: 100%;
    }
    .details_top {
      height: 559px;
    }
    .details_bottom1,
    .details_bottom2 {
      //   width: 100%;
      display: flex;
      justify-content: space-between;
      .bottom_inner {
        width: 50%;
        height: 497px;
      }
    }
  }
}
.serve {
  //   .serve_title {
  //     margin: 0 auto;
  //     text-align: center;
  //     position: relative;
  //     h2 {
  //       font-size: 28px;
  //       color: #345fc7;
  //       font-weight: 500;
  //       width: 135px;
  //       position: absolute;
  //       top: -20px;
  //       left: 50%;
  //       margin-left: -62px;
  //       background: #fff;
  //     }
  //     p {
  //       font-size: 16px;
  //       color: #999;
  //       width: 315px;
  //       height: 80px;
  //       font-family: "Arial";
  //       border: 1px solid #dcdcdc;
  //       line-height: 80px;
  //       margin: -19px auto 0;
  //       letter-spacing: 2px;
  //     }
  //   }
  //   .serve_details {
  //     width: 842px;
  //     font-size: 12px;
  //     font-family: PingFangSC-Regular, sans-serif;
  //     color: #8a8a8a;
  //     text-align: center;
  //     margin: 26px auto 100px;
  //     line-height: 25px;
  //   }
  .serve_content {
    .content_top {
      width: 756px;
      margin: 0 auto;
      overflow: hidden;
      li {
        float: left;
        font-size: 20px;
        color: #333;
        margin: 0 43px;
        cursor: pointer;
      }
      .serveActive {
        p {
          width: 80%;
          height: 2px;
          margin: 10px auto 0;
          background: #345fc7;
        }
      }
    }
    /deep/.el-carousel__container {
      height: 482px;
    }
    .content_inner {
      height: 100%;
      position: relative;
      .inner_left {
        img {
          width: 100%;
        }
      }
      .inner_right {
        position: absolute;
        top: 0;
        right: 5px;
        width: 38.5%;
        height: 100%;
        padding: 151px 0 0 36px;
        box-sizing: border-box;
        color: #333;
        h2 {
          font-size: 24px;
          margin-bottom: 54px;
        }
        p {
          width: 90%;
          font-size: 20px;
          line-height: 32px;
        }
        a {
          color: #345fc7;
          font-size: 12px;
          font-family: PingFangSC-Regular, sans-serif;
          position: absolute;
          right: 26px;
          bottom: 15px;
        }
      }
    }
  }
  .el-carousel--horizontal {
    overflow-x: hidden;
    padding: 70px 0 136px;
  }
  .el-carousel__item--card.is-active {
    width: 62.4%;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.12);
    transform: translateX(28.6%) scale(1) !important;
  }
}
// 媒体查询自适应
@media screen and (max-width: 1680px) {
  .serve {
    .serve_content {
      /deep/.el-carousel__container {
        height: 422px;
      }
      .content_inner {
        .inner_right {
          padding: 105px 0 0 32px;
          h2 {
            font-size: 23px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .serve {
    .serve_content {
      /deep/.el-carousel__container {
        height: 364px;
      }
      .content_inner {
        .inner_right {
          padding: 85px 0 0 25px;
          h2 {
            font-size: 22px;
            margin-bottom: 45px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .serve {
    .serve_content {
      /deep/.el-carousel__container {
        height: 343px;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .serve {
    .serve_content {
      /deep/.el-carousel__container {
        height: 323px;
      }
      .content_inner {
        .inner_right {
          padding: 85px 0 0 17px;
          h2 {
            font-size: 21px;
            margin-bottom: 40px;
          }
          p {
            font-size: 15px;
            margin: 7px 0;
          }
        }
      }
    }
  }
}
</style>
