<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li class="active"><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="swiper animate__backInUp animate__animated">
      <!-- <img src="../assets/image/userCase/banner.png" /> -->
      <div class="inner">
        <h2>客户案例</h2>
        <p>CASE</p>
        <img src="../assets/image/arrowwhilt.png" />
      </div>
    </div>

    <div class="function w animate__backInUp animate__animated">
      <div class="left">
        <img v-lazy="require('../assets/image/userCase/caselogo.png')" />
      </div>
      <div class="right">
        <div class="health">
          <h3>健康预警</h3>
          <p>13718次</p>
        </div>
        <div class="doctor">
          <h3>医患互动</h3>
          <p>69718次</p>
        </div>
        <div class="sos">
          <h3>SOS呼救</h3>
          <p>33910次</p>
        </div>
        <div class="share">
          <h3>用户分享</h3>
          <p>213718次</p>
        </div>
      </div>
    </div>
    <div class="government">
      <div class="title">
        <h2>政府项目合作，协助防疫工作</h2>
        <p>EPIDEMIC PREVENTION</p>
        <div class="line"></div>
      </div>
      <div
        class="management animate__fadeInUp wow animate__animated"
        data-wow-delay="1s"
      >
        <!-- <div class="title">
        <h2>数据管理系统</h2>
        <p>Data Management</p>
        <img class="left" src="../assets/interHealth/left.png" />
        <img class="right" src="../assets/interHealth/right.png" />
      </div> -->
        <div class="management_inner w">
          <div
            class="content"
            v-for="(item, index) in managementList"
            :key="index"
          >
            <h3>{{ item.title }}</h3>
            <p>{{ item.value }}</p>
          </div>
        </div>
        <div
          class="inner"
          v-lazy:background-image="require('../assets/image/computer.png')"
        >
          <!-- <img v-lazy="require('../assets/image/computer.png')" /> -->
        </div>
      </div>
      <div class="details animate__flipInX wow animate__animated">
        <img v-lazy="require('../assets/image/government.png')" />
        <div class="inner">
          <h2>战“疫”一线，守护同行</h2>
          <p>
            2020年初，疫情在我国爆发并以飞快地速度传播，国家政府迅速布局防疫工作，稳住社会安定。新冠肺炎患者主要病状以发热干咳为主，祥康瑞旗下品牌“陪伴猫”智能手表测温功能具有国家计量二级标准，可以对佩戴者体温进行实时测量。
          </p>
          <p>
            疫情期间，深圳政府与祥康瑞达成战略合作，将“陪伴猫”智能手表应用于一线防疫项目中，每日对新冠肺炎隔离场所的人员进行体温实时追踪，并且将佩戴者体征报告上传至系统，一旦有异常情况，工作人员将第一时间获得报警信息。
          </p>
        </div>
      </div>
    </div>

    <div class="Campus">
      <div class="title">
        <h2>数字化教育，量化健康</h2>
        <p>CAMPUS SOLUTIONS</p>
        <div class="line"></div>
      </div>
      <div class="content w">
        <div class="inner">
          祥康瑞智慧校园解决方案助力学校做教育改革，健康与学习并重。帮助青少儿树立健康意识&nbsp;，&nbsp;劳逸结合，追踪学生健康数值，改变不健康的生活方式。
        </div>
      </div>
    </div>

    <div class="case w">
      <div class="title">
        <h2>蜂巢案例</h2>
        <p>HONEYCOMB CASE</p>
        <div class="line"></div>
      </div>
      <!-- <div class="details animate__flip wow animate__animated"> -->
      <div
        class="details animate__zoomIn wow animate__animated"
        data-wow-duration="2s"
      >
        <img v-lazy="require('../assets/image/userCase/case.png')" />
        <p># 场景化解决方案提供商，专注于全行业智慧化落地方案</p>
      </div>
    </div>

    <div class="OnlineSer">
      <img v-lazy="require('../assets/image/userCase/caseBto.png')" />
      <div class="details">
        <h2>以诚信和智慧倾情铸造高质量、富有感情的科技精品</h2>
        <!-- <p>在线客服</p> -->
      </div>
    </div>
    <!-- <div class="why">
      <div class="title">
        <h2>为什么推荐祥康瑞医疗手表？</h2>
        <p>Why recommend xiangkangrui medical watch?</p>
      </div>
      <div class="content">
        <div class="inner w">
          <img src="../assets/image/doctor.jpg" />
          <div class="suggest">
            <h3>听听医生的建议:</h3>
            <p>
              从专业的角度看，可以了解并掌握用户长期的体征数据情况，对于整个医疗诊断和康养上来说，是非常重要的。因为医生与患者的接触并不像家庭医生那么密切，但是有了智能健康手表作为媒介，既不会触碰<span>患者隐私</span>，又可以掌握患者近期的基本情况。这种以预防为主的健康策略，<span>线下问诊与线上咨询相结合</span>，对于整个医患沟通来说更加轻松效率。
            </p>
            <p>
              其实不仅仅是病患者或者老年人，常年工作压力较大、熬夜等人群也可以佩戴，特别是像现在很多白领久坐不起，或者职场应酬等，现在亚健康人群年轻化趋势很严峻，这种随身携带的智能产品作为<span>健康提醒</span>，也是一种医疗进步。
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="experience">
      <div class="title">
        <h2>用户心得</h2>
        <p>USER EXPERIENCE</p>
        <div class="line"></div>
      </div>
      <div class="inner w">
        <div class="left"></div>
        <div class="right">
          <p>
            以前我“一日三餐无酒不欢”，到现在偶尔“小酌怡情”，祥康瑞智能手表让我的生活有了可观的变化。有一次app上的健康分析报告有疾病预警，客服打来电话建议我到医院检查。开始觉得这是一种“骚扰”，明明没任何不舒服，说得很严重。后来还是去做了心电图检查，结果显示窦性心律和偶发房性早搏等症状，医生说容易引发冠心病、心梗等危险疾病，这下就乖乖接受药物治疗，在祥康瑞健康监控下，自己也逐渐重视起健康的生活作息。现在想想，还是非常感谢当时“烦人”的预警。
          </p>
          <div class="click">点击<img src="../assets/image/click.png"></div>
        </div>
      </div>
    </div> -->

    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><p>官网首页</p></router-link>
          <router-link to="news"><p>新闻资讯</p></router-link>
          <router-link to="usercase"><h3>客户案例</h3></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
            <h3>产品中心</h3>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">131-6335-2131</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
          <p style="margin-left: 40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      managementList: [
        { title: "体温异常警告", value: "10208次" },
        { title: "轨迹追踪", value: "6295次" },
        { title: "SOS呼救", value: "2973次" },
        // { title: "用户分享", value: "213718次" },
      ],
    };
  },
  methods: {
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
  },
};
</script>
<style lang='less' scoped>
.swiper {
  position: relative;
  height: 800px;
  background: url("../assets/image/userCase/banner.png") top center no-repeat;
  // z-index: -1;
  img {
    width: 100%;
    height: 800px;
  }
  .inner {
    color: #fff;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 260px;
    h2 {
      font-weight: 400;
      font-size: 27px;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      margin: 5px 0 35px;
    }
    img {
      width: 21px;
      height: 25px;
    }
  }
}
.function {
  margin-top: -223px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 99;
  .left {
    img {
      width: 793px;
      height: 660px;
    }
  }
  .right {
    display: flex;
    justify-content: flex-start;
    width: 452px;
    height: 452px;
    flex-wrap: wrap;
    margin-left: -45px;
    //   align-items: flex-start;
    .health,
    .doctor,
    .sos,
    .share {
      width: 226px;
      height: 226px;
      //   background: #5a7cbf;
      //   box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.27);
      text-align: center;
      padding-top: 72px;
      box-sizing: border-box;
      h3 {
        font-size: 27px;
        font-weight: 400;
        margin-bottom: 13px;
      }
      p {
        font-size: 38px;
        letter-spacing: 3px;
      }
    }
    .health {
      background: #5a7cbf;
      color: #fff;
      // box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.27);
      // z-index: 99;
    }
    .doctor {
      background: #f5f5f5;
      color: #5a7cbf;
    }
    .sos {
      background: #f5f5f5;
      color: #ff6e9f;
    }
    .share {
      background: #d8d8d8;
      color: #fff;
    }
    .health:hover,
    .doctor:hover,
    .sos:hover,
    .share:hover {
      box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.27);
      z-index: 99;
    }
  }
}
.government,
.case,
.Campus {
  .title {
    text-align: center;
    h2 {
      font-size: 28px;
      color: #434343;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      font-family: Arial;
      color: #cbcbcb;
      margin: 8px 0 34px;
    }
    .line {
      margin: 0 auto;
      width: 47px;
      height: 3px;
      background: #345fc7;
    }
  }
}

.government {
  margin-top: 130px;
  .management {
    // margin-top: 90px;
    .management_inner {
      width: 950px;
      display: flex;
      justify-content: space-between;
      .content {
        // width: 150px;
        text-align: center;
        margin: 95px 0 66px;
        h3 {
          font-size: 27px;
          color: #434343;
        }
        p {
          margin-top: 12px;
          font-size: 38px;
          color: #345fc7;
        }
      }
    }
    .inner {
      background-repeat: no-repeat;
      background-position: top center;
      height: 900px;
      // img {
      //   width: 100%;
      //   height: 900px;
      // }
    }
  }
  .details {
    position: relative;
    margin-top: 80px;
    img {
      width: 100%;
      height: 688px;
    }
    .inner {
      width: 840px;
      height: 486px;
      background: #ffffff;
      opacity: 0.83;
      padding: 86px 46px 0;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 197px;
      h2 {
        text-align: center;
        font-size: 26px;
        color: #cf2c0b;
        font-weight: 400;
        margin-bottom: 40px;
      }
      p {
        font-size: 20px;
        line-height: 45px;
        text-indent: 2em;
      }
    }
  }
}
.Campus {
  box-sizing: border-box;
  // padding-top: 50px;
  padding-top: 130px;
  margin-bottom: 108px;
  // height: 740px;
  height: 881px;
  background: url("../assets/image/userCase/Campus.png") no-repeat top center;
  .content {
    // width: 500px;
    .inner {
      margin: 176px 0 0 70px;
      font-family: PingFangSC-Regular, sans-serif;
      width: 430px;
      font-size: 25px;
      letter-spacing: 2px;
      line-height: 40px;
    }
  }
}
.case {
  .details {
    margin: 102px auto 242px;
    img {
      width: 100%;
      height: 930px;
    }
    p {
      text-align: right;
      font-size: 28px;
      color: #345fc7;
      margin-right: -45px;
    }
  }
}
.OnlineSer {
  position: relative;
  height: 356px;
  img {
    width: 100%;
    height: 357px;
  }
  .details {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%,-50%);
    // top: 110px;
    top: 50%;
    color: #fff;
    text-align: center;
    h2 {
      font-size: 30px;
    }
    p {
      width: 220px;
      height: 50px;
      background: #345fc7;
      line-height: 50px;
      cursor: pointer;
      margin: 102px auto 0;
    }
  }
}
.why {
  .title {
    text-align: center;
    color: #445bb5;
    h2 {
      font-weight: 400;
      font-size: 48px;
      font-family: PingFang SC;
      letter-spacing: 3px;
    }
    p {
      font-size: 20px;
      //   letter-spacing: px;
      margin: 25px 0 90px;
    }
  }
  .content {
    height: 457px;
    background: #f6f6f6;
    .inner {
      height: 100%;
      position: relative;
      img {
        width: 130px;
        height: 133px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .suggest {
        width: 1009px;
        position: absolute;
        left: 165px;
        top: 80px;
        h3 {
          font-size: 29px;
          color: #575757;
          font-weight: 400;
          letter-spacing: 2px;
          margin-bottom: 50px;
        }
        p {
          font-size: 20px;
          color: #a1a1a1;
          text-indent: 2em;
          letter-spacing: 1px;
          span {
            color: #445bb5;
          }
        }
      }
    }
  }
}
.experience {
  margin: 159px 0 140px;
  .inner {
    margin-top: 80px;
    height: 618px;
    background: #6b6b6b;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.16);
    padding: 42px 0 0 25px;
    box-sizing: border-box;
    position: relative;
    .left {
      width: 495px;
      height: 544px;
      background: #ffffff;
      border: 1px solid #707070;
    }
    .right {
      position: absolute;
      bottom: 34px;
      right: 43px;
      p {
        width: 600px;
        font-size: 20px;
        line-height: 36px;
        color: #ffffff;
        text-indent: 2em;
        margin-bottom: 119px;
      }
      .click {
        float: right;
        img {
          width: 28px;
          height: 21px;
        }
      }
    }
  }
}
</style>
