import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'
import interHealthy from '../views/interHealthy.vue'
import OnlineDoctor from '../views/OnlineDoctor.vue'
import Remote from '../views/Remote .vue'
import Monitor from '../views/Monitor.vue'
import News from '../views/News.vue'
import NewsDetails from '../views/NewsDetails.vue'
import Product from '../views/Product.vue'
import UserCase from '../views/UserCase.vue'
import aboutUs from '../views/aboutUs.vue'
import IntelPension from '../views/IntelPension.vue'
import SmartCampus from '../views/SmartCampus.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    //在此处加入以下内容
    scrollBehavior(to,from,saveTop){
      if(saveTop){
        return saveTop;
      }else{
        return {x:0,y:0}
      }
    },
  routes: [
    { path: '/', redirect: '/home'},
    { path: '/home', name: 'Home', component: Home,  },
    { path: '/login', component: Login },
    { path: '/interhealthy', component: interHealthy },
    { path: '/onlinedoctor', component: OnlineDoctor },
    { path: '/remote', component: Remote },
    { path: '/monitor', component: Monitor },
    { path: '/news', component: News },
    { path: '/newsdetails', component: NewsDetails },
    { path: '/product', component: Product },
    { path: '/usercase', component: UserCase },
    { path: '/aboutus', component: aboutUs },
    { path: '/intelpension', component: IntelPension },
    { path: '/smartcampus', component: SmartCampus },
  ]
})

export default router
