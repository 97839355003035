<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve active" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="swiper animate__backInUp animate__animated">
      <!-- <img src="../assets//image/product/banner.png" /> -->
      <div class="inner">
        <h2>我们的产品</h2>
        <p>PRODUCT</p>
        <img src="../assets/image/arrowwhilt.png" />
      </div>
    </div>
    <div class="product w">
      <div class="title">
        <el-carousel
          :autoplay="false"
          indicator-position="none"
          arrow="never"
          type="card"
          height="50px"
          ref="titleRef"
          @change="titlechangeData"
        >
          <el-carousel-item v-for="item in proTitle" :key="item.index">
            <h3 class="medium">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content">
        <el-carousel
          :autoplay="false"
          indicator-position="none"
          arrow="never"
          type="card"
          height="430px"
          ref="contentRef"
          @change="contentchangeData"
        >
          <el-carousel-item v-for="item in proContent" :key="item.index">
            <img v-lazy="item.url" />
          </el-carousel-item>
        </el-carousel>
        <img class="shadowimg" src="../assets/image/product/shadow.png" />
      </div>
    </div>
    <div class="show w animate__backInDown wow animate__animated">
      <div class="title">
        <h2>产品中心</h2>
        <p>PRODUCT CENTER</p>
        <div class="line"></div>
      </div>
      <div class="content w" v-if="showIndex == 0">
        <div class="inner watch01">
          <img v-if="XKR001Id == 0" class="proImg" v-lazy="XKR001.url0" />
          <img v-if="XKR001Id == 1" class="proImg" v-lazy="XKR001.url1" />
          <img v-if="XKR001Id == 2" class="proImg" v-lazy="XKR001.url2" />
          <img v-if="XKR001Id == 3" class="proImg" v-lazy="XKR001.url3" />
          <h2>{{ XKR001.title }}</h2>
          <p>{{ XKR001.content }}</p>
          <!-- <span>立即购买 ></span> -->
          <img
            class="shadowimg"
            v-lazy="require('../assets/image/product/shadow.png')"
          />
          <ul>
            <li
              :class="XKR001Id == index ? 'active' : ''"
              v-for="(item, index) in 4"
              :key="index"
              @click="XKR001Change(index)"
            >
              <div class="circle"></div>
            </li>
          </ul>
        </div>
        <div class="inner watch01">
          <div v-for="(item, index) in M007.urlList" :key="index">
            <img v-if="M007ID == index" class="proImg" v-lazy="item.url" />
          </div>
          <!-- <img v-if="XKR001Id == 0" class="proImg" :src="M007.url0" />
          <img v-if="XKR001Id == 1" class="proImg" :src="M007.url1" />
          <img v-if="XKR001Id == 2" class="proImg" :src="M007.url2" />
          <img v-if="XKR001Id == 3" class="proImg" :src="M007.url3" /> -->
          <h2>{{ M007.title }}</h2>
          <p>{{ M007.content }}</p>
          <!-- <span>立即购买 ></span> -->
          <img
            class="shadowimg"
            v-lazy="require('../assets/image/product/shadow.png')"
          />
          <ul>
            <li
              :class="M007ID == index ? 'active' : ''"
              v-for="(item, index) in 4"
              :key="index"
              @click="M007Change(index)"
            >
              <div class="circle"></div>
            </li>
          </ul>
        </div>
        <div class="inner" v-for="(item, index) in watchList" :key="index">
          <img class="proImg" v-lazy="item.url" />
          <h2>{{ item.title }}</h2>
          <p>{{ item.content }}</p>
          <!-- <span>立即购买 ></span> -->
          <img
            class="shadowimg"
            v-lazy="require('../assets/image/product/shadow.png')"
          />
          <!-- <ul>
            <li v-for="item in 4" :key="item.index">
              <div class="circle"></div>
            </li>
          </ul> -->
        </div>
      </div>

      <div class="content w" v-if="showIndex == 1">
        <div class="inner" v-for="(item, index) in balanceList" :key="index">
          <img class="proImg" v-lazy="item.url" />
          <h2>身高体重秤</h2>
          <p>专业24项体质数据分析，记录用户体型标准并形成趋势图</p>
          <!-- <span>立即购买 ></span> -->
          <img
            class="shadowimg"
            v-lazy="require('../assets/image/product/shadow.png')"
          />
          <!-- <ul>
            <li v-for="item in 4" :key="item.index" @click="watchChang(index)">
              <div class="circle"></div>
            </li>
          </ul> -->
        </div>
      </div>
      <div class="content w" v-if="showIndex == 2">
        <div class="inner" v-for="(item, index) in skipList" :key="index">
          <img class="proImg" v-lazy="item.url" />
          <h2>跳绳</h2>
          <p>蓝牙传输技术，简单关联实现数据多端共享，智能生成数据报告</p>
          <!-- <span>立即购买 ></span> -->
          <img
            class="shadowimg"
            v-lazy="require('../assets/image/product/shadow.png')"
          />
          <!-- <ul>
            <li v-for="item in 4" :key="item.index">
              <div class="circle"></div>
            </li>
          </ul> -->
        </div>
      </div>
      <!-- <div class="title title02">
        <h2>产品中心</h2>
        <p>USER EXPERIENCE</p>
        <div class="line"></div>
      </div>
      <div class="content content2">
        <div class="bck"></div>
        <div class="inner w">
          <div class="inner_left fl">
            <h3>XIANG KANG RUI</h3>
            <h2>祥康瑞<span>M</span>系列</h2>
            <div class="explain">重新定义手表，让健康看得见</div>
            <p>
              它不止是一款普通手表，更是一个贴身守护
              的健康管理管家。每日一份健康解析，让健 康看得见、简单化。
            </p>
            <div class="btn">立即购买</div>
          </div>
          <div class="inner_right fl">
            <img src="../assets/image/M006.png" />
            <p>M006</p>
          </div>
        </div>
      </div> -->
    </div>

    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><h3>官网首页</h3></router-link>
          <router-link to="news"><p>新闻资讯</p></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
            <h3>产品中心</h3>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">131-6335-2131</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
        <p style="margin-left:40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
import pro from "../assets/image/product01.png";
export default {
  data() {
    return {
      showIndex: 0,
      XKR001Id: 0,
      M007ID: 0,
      active: false,
      proTitle: ["智能手表", "身高体重秤", "智慧跳绳"],
      proContent: [
        {
          url: require("../assets/image/product/11.png"),
        },
        { url: require("../assets/image/product/22.png") },
        { url: require("../assets/image/product/33.png") },
      ],
      XKR001: {
        url0: require("../assets/image/product/2.png"),
        url1: require("../assets/image/product/1.png"),
        url2: require("../assets/image/product/3.png"),
        url3: require("../assets/image/product/4.png"),

        title: "祥康瑞 XKR001 健康手表",
        content:
          "美国FDA+中国NMPA双认证 采集心率 血氧监测 动态血压 运动模式 SOS呼救GPS定位 夜间贴身提醒",
      },
      M007: {
        urlList: [
          { url: require("../assets/image/product/m007/M007.png") },
          { url: require("../assets/image/product/m007/M00702.png") },
          { url: require("../assets/image/product/m007/M00703.png") },
          { url: require("../assets/image/product/m007/M00704.png") },
        ],
        // url0: require("../assets/image/product/m007/M007.png"),
        // url1: require("../assets/image/product/m007/M00702.png"),
        // url2: require("../assets/image/product/m007/M00703.png"),
        // url3: require("../assets/image/product/m007/M00704.png"),
        title: "祥康瑞 M007 健康手表",
        content:
          "全天动态智能体温测试 采集心率 血氧监测 动态血压 夜间贴身提醒功能 多国语言展示",
      },
      watchList: [
        {
          url: require("../assets/image/product/moo1.png"),
          title: "祥康瑞 M001 健康手表",
          content:
            "全天候自动采集心率 血氧监测 动态血压 运动模式 夜间贴身守护 卡路里",
        },
        {
          url: require("../assets/image/product/MOO2.png"),
          title: "祥康瑞 M002 健康手表",
          content:
            "全天候自动采集心率 血氧监测 动态血压 运动模式 夜间贴身守护 卡路里",
        },
        {
          url: require("../assets/image/product/MOO3.png"),
          title: "祥康瑞 M003 健康手表",
          content:
            "全天候自动采集心率 血氧监测 动态血压 运动模式 夜间贴身守护 卡路里",
        },
        {
          url: require("../assets/image/product/M004.png"),
          title: "祥康瑞 M004 健康手表",
          content:
            "全天候自动采集心率 血氧监测 动态血压 运动模式 夜间贴身守护 卡路里",
        },
        {
          url: require("../assets/image/product/M005.png"),
          title: "祥康瑞 M005 健康手表",
          content:
            "全天候自动采集心率 血氧监测 动态血压 运动模式 夜间贴身守护 卡路里",
        },
        {
          url: require("../assets/image/product/M006.png"),
          title: "祥康瑞 M006 健康手表",
          content:
            "全天候自动采集心率 血氧监测 动态血压 运动模式 夜间贴身守护 卡路里",
        },
        {
          url: require("../assets/image/product/M008.png"),
          title: "祥康瑞 M008 健康手表",
          content:
            "全天动态智能体温测试 采集心率 血氧监测 动态血压 蓝牙语音通话 夜间贴身提醒功能",
        },
        {
          url: require("../assets/image/product/M009.png"),
          title: "祥康瑞 M009 健康手表",
          content:
            "全天候自动采集心率 血氧监测 动态血压 运动模式 夜间贴身守护 卡路里",
        },
        {
          url: require("../assets/image/product/M010.png"),
          title: "祥康瑞 M010 健康手表",
          content:
            "便捷消费支付 采集心率 动态血压 血氧监测 WiFi网络链接 GPS定位",
        },
        {
          url: require("../assets/image/product/MO11.png"),
          title: "祥康瑞 M011 健康手表",
          content:
            "双向通话畅聊无阻 动态血压 采集心率 体温检测 血氧监测 SOS呼救GPS智能定位",
        },
        {
          url: require("../assets/image/product/Z001.png"),
          title: "祥康瑞 Z001 健康手表",
          content:
            "电子围栏自主设置范围 双向通话 动态血压 采集心率 体温检测 血氧监测 SOS呼救",
        },
      ],
      balanceList: [{ url: require("../assets/image/product/22.png") }],
      skipList: [{ url: require("../assets/image/product/33.png") }],
    };
  },
  methods: {
    titlechangeData(val) {
      this.$refs.contentRef.setActiveItem(val);
      this.showIndex = val;
    },
    contentchangeData(val) {
      this.$refs.titleRef.setActiveItem(val);
      this.showIndex = val;
    },
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
    XKR001Change(id) {
      this.XKR001Id = id;
      // console.log(this.XKR001Id);
    },
    M007Change(id) {
      this.M007ID = id;
      // console.log(this.M007ID);
    },
  },
};
</script>
<style lang='less' scoped>
.swiper {
  position: relative;
  height: 400px;
  background: url("../assets/image/product/banner.png") top center no-repeat;
  img {
    width: 100%;
    height: 400px;
  }
  .inner {
    color: #fff;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 22px;
    h2 {
      font-weight: 400;
      font-size: 27px;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      margin: 5px 0 35px;
    }
    img {
      width: 21px;
      height: 25px;
    }
  }
}
.product {
  margin-top: 58px;
  .title {
    width: 580px;
    margin: 0 auto 116px;
    text-align: center;
    font-size: 22px;
    font-family: DengXian;
    .el-carousel__item--card {
      width: 50%;
      color: #333;
    }
    .el-carousel__item--card.is-active {
      h3 {
        color: #000;
        font-weight: bold;
        font-size: 28px;
      }
    }
  }
  .content {
    width: 100%;
    // margin: 0 auto;
    text-align: center;
    // position: relative;
    // .el-carousel__item--card {
    //   transform: scale(0.5)!important;
    // }
    // .el-carousel__item--card.is-active {
    //   transform: scale(1)!important;
    // }
    img {
      // width: 427px;
      // height: 428px;
      width: 254px;
      height: 256px;
    }
    .el-carousel--horizontal {
      overflow-x: visible;
    }
    .el-carousel__item--card.is-active {
      img {
        // width: 354px;
        // height: 359px;
        width: 400px;
        height: 400px;
      }
      margin-top: 70px;
    }
    .shadowimg {
      margin: 76px auto 0;
      width: 195px;
      height: 11px;
    }
  }
}
.show {
  margin: 130px auto 199px;
  // height: 1660px;
  .title {
    text-align: center;
    h2 {
      font-size: 28px;
      color: #434343;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      font-family: Arial;
      color: #cbcbcb;
      margin: 8px 0 34px;
    }
    .line {
      margin: 0 auto;
      width: 47px;
      height: 3px;
      background: #345fc7;
    }
  }
  .content {
    // height: 748px;
    margin: 65px auto 0;
    // padding-top: 174px;
    // box-sizing: border-box;
    // position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .inner {
      width: 380px;
      height: 464px;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      position: relative;
      margin-top: 34px;
      margin: 20px 10px 0;
      .proImg {
        position: absolute;
        top: 115px;
        left: 83px;
        width: 185px;
        height: 185px;
      }
      h2 {
        position: absolute;
        bottom: 89px;
        left: 16px;
        font-size: 20px;
        color: #0f0f0f;
      }
      p {
        position: absolute;
        left: 16px;
        bottom: 44px;
        font-size: 14px;
        color: #b5b5b5;
        line-height: 21px;
        width: 343px;
      }
      span {
        position: absolute;
        bottom: 17px;
        right: 18px;
        font-size: 13px;
        line-height: 24px;
        color: #dcdcdc;
      }
      .shadowimg {
        width: 115px;
        height: 8px;
        position: absolute;
        left: 97px;
        top: 323px;
        display: none;
      }
      ul {
        position: absolute;
        right: 33px;
        top: 149px;
        li {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #e0e0e0;
          padding: 3px;
          // box-sizing: border-box;
          margin-top: 18px;
          .circle {
            width: 12px;
            height: 12px;
            background: #000000;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.24);
            border-radius: 50%;
            opacity: 0.73;
          }
        }
        .active {
          border: 1px solid #313131;
        }
        li:nth-of-type(2) {
          .circle {
            background: #b67cdc;
          }
        }
      }
    }
    .inner:hover {
      .proImg {
        width: 219px;
        height: 219px;
        left: 54px;
        top: 81px;
      }
      .shadowimg {
        display: block;
      }
      h2 {
        color: #345fc7;
      }
      p {
        color: #345fc7;
      }
      span {
        color: #345fc7;
      }
    }
    .watch01 {
      ul {
        li:nth-of-type(2) {
          .circle {
            background: #e93824;
          }
        }
        li:nth-of-type(3) {
          .circle {
            background: #215a26;
          }
        }
        li:nth-of-type(4) {
          .circle {
            background: #526bbc;
          }
        }
      }
    }
  }
}
</style>
