<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li class="active"><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="swiper animate__backInUp animate__animated">
      <!-- <img src="../assets/interHealth/banner.png" /> -->
      <div class="inner">
        <h2>服务中心</h2>
        <p>CENTER</p>
        <img src="../assets/image/arrowwhilt.png" />
      </div>
    </div>
    <!-- <div class="healhthy w">
      <div class="title">
        <h2>互联网+健康管理</h2>
        <p>Internet+Health Management</p>
        <img class="left" src="../assets/interHealth/left.png" />
        <img class="right" src="../assets/interHealth/right.png" />
      </div>
      <div class="details_one">
        <div class="inner" v-for="(item, index) in 3" :key="index"></div>
      </div>
      <div class="details_two">
        <div class="inner" v-for="(item, index) in 2" :key="index"></div>
      </div>
      <p class="explain">
        祥康瑞智能手表，采用传统中医诊脉与当代人工智能技术相结合，每半小时系统自动采集用户的心率、血压、血氧等体征信息，并将健
        康报告上传至APP，传达给家人和医生。通过用户的健康监测，系统会推送专属化的健康饮食与作息建议给到用户，另外，当用户有疾病风险趋势，系统将第一时间联系并通知家人，避免悲剧发生。
      </p>
    </div> -->

    <div class="management">
      <div class="title">
        <h2>全行业场景化解决方案提供商</h2>
        <p>SOLUTION PROVIDER</p>
        <div class="line"></div>
      </div>
      <div
        class="inner"
        v-lazy:background-image="require('../assets/image/solution.jpg')"
      >
      </div>
    </div>
    <div class="advantage animate__fadeInUp wow animate__animated">
      <div class="title">
        <h2>我们的优势</h2>
        <p>OUR ADVANTAGES</p>
        <img class="left" src="../assets/interHealth/left.png" />
        <img class="right" src="../assets/interHealth/right.png" />
      </div>
      <div class="content">
        <div class="inner" v-for="(item, index) in advantageList" :key="index">
          <img v-lazy="item.url" />
          <h2>{{ item.title }}</h2>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <img class="bg" v-lazy="require('../assets/interHealth/advantage.png')" />
    </div>
    <router-link to="smartcampus">
      <div
        v-lazy:background-image="require('../assets/interHealth/serve01.png')"
        class="SmartCampus animate__fadeInUp wow animate__animated"
      ></div>
    </router-link>
    <router-link to="intelpension">
      <div
        v-lazy:background-image="require('../assets/interHealth/serve02.png')"
        class="IntelPension animate__fadeInUp wow animate__animated"
      ></div>
    </router-link>
    <div
      v-lazy:background-image="require('../assets/interHealth/serve03.png')"
      class="medical animate__fadeInUp wow animate__animated"
    ></div>

    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><p>官网首页</p></router-link>
          <router-link to="news"><p>新闻资讯</p></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><h3>服务中心</h3></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
            <h3>产品中心</h3>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><p>智慧校园</p></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">131-6335-2131</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
          <p style="margin-left: 40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      advantageList: [
        {
          url: require("../assets/interHealth/01.png"),
          title: "专业赋能",
          content: "追求品质，专业服务",
        },
        {
          url: require("../assets/interHealth/02.png"),
          title: "奋勇前进",
          content: "不畏困难，砥砺前行",
        },
        {
          url: require("../assets/interHealth/03.png"),
          title: "敦本务实",
          content: "务实笃行，行稳致远",
        },
        {
          url: require("../assets/interHealth/04.png"),
          title: "谋求创新",
          content: "与时俱进，开拓创新",
        },
      ],
    };
  },
  methods: {
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
  },
};
</script>
<style lang='less' scoped>
.swiper {
  height: 400px;
  position: relative;
  background: url("../assets/interHealth/banner.png") top center no-repeat;
  .inner {
    color: #fff;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 17px;
    h2 {
      font-weight: 400;
      font-size: 27px;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      margin: 5px 0 35px;
    }
    img {
      width: 21px;
      height: 25px;
    }
  }

  img {
    width: 100%;
    height: 400px;
  }
  .introduce {
    position: absolute;
    top: 192px;
    left: 0;
    h3 {
      font-size: 28px;
      color: #345fc7;
      margin-bottom: 30px;
    }
    p {
      width: 668px;
      height: 154px;
      font-size: 18px;
      line-height: 34px;
      color: #313131;
    }
  }
}
.hide {
  display: none;
}
.title {
  height: 55px;
  text-align: center;
  margin: 0 auto;
  position: relative;
  h2 {
    font-size: 28px;
    font-weight: 400;
    color: #2a2a2a;
  }
  p {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #cbcbcb;
    //   margin-top: -1px;
  }
  .left {
    position: absolute;
    left: 0;
    top: 50%;
  }
  .right {
    position: absolute;
    right: 0;
    top: 50%;
  }
}
.healhthy {
  margin-top: 100px;
  .title {
    width: 310px;
  }
  .details_one {
    display: flex;
    justify-content: space-between;
    margin: 110px 0;
  }
  .inner {
    width: 106px;
    height: 106px;
    background: #efefef;
    border: 1px solid #707070;
  }
  .details_two {
    display: flex;
    justify-content: space-around;
  }
  .explain {
    font-size: 20px;
    line-height: 34px;
    color: #666669;
    margin: 124px 0;
  }
}
.management {
  margin-top: 90px;
  .title {
    width: 365px;
    P{
      margin: 8px 0 34px;
    }
    .line {
      margin: 0 auto;
      width: 47px;
      height: 3px;
      background: #345fc7;
    }
  }
  .management_inner {
    width: 950px;
    display: flex;
    justify-content: space-between;
    .content {
      // width: 150px;
      text-align: center;
      margin: 95px 0 66px;
      h3 {
        font-size: 27px;
        color: #434343;
      }
      p {
        margin-top: 12px;
        font-size: 38px;
        color: #345fc7;
      }
    }
  }
  .inner {
    background-repeat: no-repeat;
    background-position: top center;
    height: 900px;
    // img {
    //   width: 100%;
    //   height: 900px;
    // }
  }
}
.advantage {
  height: 502px;
  // background: #000;
  color: #fff;
  position: relative;
  .title {
    width: 310px;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    h2 {
      color: #fff;
      margin-bottom: 10px;
    }
  }
  .content {
    width: 899px;
    position: absolute;
    bottom: 68px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    .inner {
      text-align: center;
      img {
        width: 97px;
        height: 108px;
      }
      h2 {
        font-size: 18px;
        margin: 49px 0 13px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .bg {
    width: 100%;
    height: 100%;
  }
}
.SmartCampus {
  width: 100%;
  height: 720px;
  background-repeat: no-repeat;
  background-position: top right;
  // background: url("../assets/interHealth/serve01.png") no-repeat top center;
}
.IntelPension {
  width: 100%;
  height: 725px;
  background-repeat: no-repeat;
  background-position: top center;
  // background: url("../assets/interHealth/serve02.png") no-repeat top center;
}
.medical {
  width: 100%;
  height: 723px;
  background-repeat: no-repeat;
  background-position: top center;
  // background: url("../assets/interHealth/serve03.png") no-repeat top center;
}
</style>
