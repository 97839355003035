import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/index.less'
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper  from 'vue-awesome-swiper'
import './plugins/element.js'
import axios from 'axios'
import 'animate.css';
import WOW from 'wow.js';
import VueLazyload from 'vue-lazyload'
import Customer from "@/components/Customer";  //全局引入组件
// import '../src/trail/index.js'

// axios.defaults.baseURL = 'https://192.168.3.10:12000/api/'
axios.defaults.baseURL = 'https://xiangkangrui.com/api/' 

new WOW(
  {
    offset: 150,
  }
).init();
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(VueLazyload)
Vue.component('Customer',Customer)   //注册组件

const loadimage = require('./assets/loading.gif')
// const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: errorimage,
  loading: loadimage,
  attempt: 1
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
