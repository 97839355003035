<template>
  <div>
    <div class="swiper">
      <div class="banner w">
        <div class="logo"></div>
        <div class="banner_inner">
          <ul>
            <li>官网首页</li>
            <li>新闻资讯</li>
            <li>服务中心</li>
            <li>客户案例</li>
            <li>产品中心</li>
            <li>关于我们</li>
          </ul>
        </div>
      </div>
      <img src="../assets/interHealth/content.png" />

      <div class="content w">
        <div class="inner">
          <h4>smart watch</h4>
          <h2>祥康瑞智能手表</h2>
          <div class="line"></div>
          <div class="btn">立即购买</div>
        </div>
      </div>
    </div>
    <div class="doctor">
      <div class="details w">
        <div class="title">
          <h2>实时监测</h2>
          <p>real-time monitoring</p>
          <img class="left" src="../assets/interHealth/left.png" />
          <img class="right" src="../assets/interHealth/right.png" />
        </div>
        <div class="details_bottom">
          <div class="inner">
            <div class="left"></div>
            <div class="right">
              <h2>同步数据</h2>
              <p>
                佩戴者通过手表和app界面，随时查看 相关体征数据，了解自身健康情况。
              </p>
            </div>
          </div>
          <div class="inner">
            <div class="right">
              <h2>健康监测</h2>
              <p>
                用户可通过心率/血压/血氧测试，实 时观察身体状况.
              </p>
            </div>
            <div class="left"></div>
          </div>
          <div class="inner">
            <div class="left"></div>
            <div class="right">
              <h2>在线观察</h2>
              <p>
                医生版关联用户，实时查看用户健康数据、管理用户健康状态、统计分析高风险用户，掌握用户健康信号并提供健康指导等服务内容
              </p>
            </div>
          </div>
          <div class="inner">
            <div class="right">
              <h2>在线观察</h2>
              <p>
                医生版关联用户，实时查看用户健康数据、管理用户健康状态、统计分析高风险用户
              </p>
            </div>
            <div class="left"></div>
          </div>
          <div class="inner">
            <div class="left"></div>
            <div class="right">
              <h2>SOS紧急求助</h2>
              <p>
                如遇突发情况与危险，GPS定位器SOS 紧急求助。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <h3>官网首页</h3>
          <p>新闻资讯</p>
          <p>客户案例</p>
          <p>产品中心</p>
          <p>关于我们</p>
        </div>
        <div class="two">
          <p>服务中心</p>
          <p>互联网+健康管理</p>
          <p>远程守护</p>
          <h3>实时监测</h3>
          <p>在线医生</p>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p>8000-0000-0000</p>
        </div>
      </div>
    </div>

    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
        <p style="margin-left:40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang='less' scoped>
.swiper {
  img {
    width: 100%;
    height: 480px;
  }
  .content {
    position: relative;
    .inner {
      position: absolute;
      top: -266px;
      h4 {
        font-size: 20px;
        font-family: Arial;
        font-weight: 400;
        color: #b8b8b8;
      }
      h2 {
        font-size: 36px;
        font-family: Source Han Sans CN;
        color: #5d5d5d;
        margin: 17px 0 14px;
      }
      .line {
        width: 60px;
        height: 2px;
        background: #345fc7;
      }
      .btn {
        width: 148px;
        height: 36px;
        border: 1px solid #7b7b7b;
        background: #f3f3f3;
        border-radius: 25px;
        text-align: center;
        color: #345fc7;
        line-height: 36px;
        font-size: 15px;
        margin-top: 50px;
      }
    }
  }
}
.doctor {
  width: 100%;
//   height: 2195px;
  padding: 83px 0 140px;
  box-sizing: border-box;
  background: #F2F8FE;
  .details {
    .title {
      width: 310px;
      height: 55px;
      text-align: center;
      margin: 0 auto 104px;
      position: relative;
      h2 {
        font-size: 28px;
        font-weight: 400;
        color: #2a2a2a;
      }
      p {
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: #cbcbcb;
        //   margin-top: -1px;
      }
      .left {
        position: absolute;
        left: 0;
        top: 50%;
      }
      .right {
        position: absolute;
        right: 0;
        top: 50%;
      }
    }
    .details_bottom {
      .inner {
        // overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .left {
          width: 711px;
          height: 454px;
          background: #ffffff;
          border: 1px solid #e1e1e1;
        }
        .right {
          padding: 0 54px 0 25px;
          h2 {
            text-align: center;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 50px;
          }
          p {
            width: 408px;
            font-size: 24px;
            line-height: 45px;
            color: #989898;
          }
        }
      }
    }
  }
}
</style>
