<template>
  <div>
    <!-- 导航栏 -->
    <div class="banner w">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="banner_inner">
        <ul>
          <router-link to="home">
            <li><span>官网首页</span></li>
          </router-link>
          <router-link to="news">
            <li><span>新闻资讯</span></li>
          </router-link>
          <router-link to="interhealthy">
            <li><span>服务中心</span></li>
          </router-link>
          <router-link to="usercase">
            <li><span>客户案例</span></li>
          </router-link>
          <router-link to="product">
            <li class="serve active" @mouseenter="enter" @mouseleave="leave">
              <span>项目中心</span>
              <div :class="active ? 'serve_inner' : 'hide'">
                <dl>
                  <router-link to="product"><dd>智能硬件</dd></router-link>
                  <router-link to="smartcampus"><dd>智慧校园</dd></router-link>
                  <router-link to="intelpension"><dd>智慧养老</dd></router-link>
                  <router-link to=""><dd>智慧医疗</dd></router-link>
                </dl>
              </div>
            </li>
          </router-link>
          <router-link to="aboutus">
            <li><span>关于我们</span></li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="swiper animate__backInUp animate__animated">
      <!-- <img src="../assets/image/SmartCampus/banner.png" /> -->
      <div class="inner">
        <h2>智慧校园</h2>
        <p>SCHOOLYARD</p>
        <img src="../assets/image/arrowblack.png" />
      </div>
    </div>
    <div class="show">
      <div class="one">
        <h2>打造智慧生态圈</h2>
        <p>
          智能硬件通过蓝牙，将学生体测数据上传至服务器 数据共享同步至学校、教师、家长及门店端 实现学校、教师与家长的高效率协作办公 助力周边门店的营销运营。
        </p>
      </div>
      <div class="two">
        <img v-lazy="require('../assets/image/SmartCampus/01.png')" />
      </div>
      <div class="three animate__backInRight wow animate__animated">
        <img v-lazy="require('../assets/image/SmartCampus/02.png')" />
      </div>
    </div>

    <div class="how animate__bounceInUp wow animate__animated">
      <div class="details">
        <h2>如何实现校园健康教学？</h2>
        <p>How to realize campus health teaching？</p>
      </div>
      <!-- <img src="../assets/image/SmartCampus/how.png" /> -->
    </div>

    <div class="Solution animate__backInDown wow animate__animated">
      <div class="title">
        <h2>解决方案</h2>
        <p>SOLUTION</p>
        <div class="line"></div>
      </div>
      <div class="content">
        <div class="content_inner one">
          <h2>学校系统</h2>
          <p>数据可视化展示，方便校领导了解本校学生整体健康水平和改善方向。</p>
        </div>
        <div class="content_inner two">
          <h2>老师APP</h2>
          <p>
            方便管理学生信息，制作课程表，布置体能作业和观察每位学生的体侧报告，通过对数据的分析更好地为学生提供针对性健康解决方案。
          </p>
        </div>
        <div class="content_inner three">
          <h2>家长APP</h2>
          <p>
            查看孩子的健康监测记录，健康图谱更好的陪伴孩子健康成长，更加精准的了解当前孩子的健康情况，配合教师做体能作业打卡。
          </p>
        </div>
        <!-- <div class="content_inner four">
          <h2>门店端</h2>
          <p>
            联动学校周边商圈，实现资源共享，精准营销，
            打造智慧生态圈，实现智慧零售的解决方案。
          </p>
        </div> -->
      </div>
    </div>

    <!-- 页脚 -->
    <div class="footer">
      <div class="footer_inner w">
        <div class="one">
          <router-link to="home"><h3>官网首页</h3></router-link>
          <router-link to="news"><p>新闻资讯</p></router-link>
          <router-link to="usercase"><p>客户案例</p></router-link>
          <router-link to="interhealthy"><p>服务中心</p></router-link>
          <router-link to="aboutus"><p>关于我们</p></router-link>
        </div>
        <div class="two">
          <router-link to="product">
            <p>产品中心</p>
          </router-link>
          <router-link to="product">
            <p>智慧硬件</p>
          </router-link>
          <router-link to="smartcampus"><h3>智慧校园</h3></router-link>
          <router-link to="intelpension"><p>智慧养老</p></router-link>
          <router-link to=""><p>智慧医疗</p></router-link>
        </div>
        <div class="line"></div>
        <div class="contact_us">
          <h3>联系我们</h3>
          <div class="code">
            <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信公众号</p>
            </div>
            <!-- <div class="code_inner">
              <img src="../assets/image/code.png" alt="" />
              <p>微信小程序</p>
            </div> -->
            <div class="code_inner">
              <img src="../assets/image/cuser.jpg" alt="" />
              <p>客服微信</p>
            </div>
          </div>
          <p class="address">
            深圳市龙岗区龙城街道回龙铺社区恒明湾创汇中心1栋AB座1702B
          </p>
          <p class="phone">131-6335-2131</p>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="w">
        <p>@2022 深圳市祥康瑞智能科技有限公司</p>
        <a href="https://beian.miit.gov.cn/">
        <p style="margin-left:40px">粤ICP备2021173604号</p>
        </a>
      </div>
    </div>
    <customer></customer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    enter() {
      this.active = true;
    },
    leave() {
      this.active = false;
    },
  },
};
</script>
<style lang='less' scoped>
.swiper {
  height: 400px;
  position: relative;
  background: url("../assets/image/SmartCampus/banner.png") top center no-repeat;
  .inner {
    color: #000;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 17px;
    h2 {
      font-weight: 400;
      font-size: 27px;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      margin: 5px 0 35px;
    }
    img {
      width: 21px;
      height: 25px;
    }
  }
  img {
    width: 100%;
    height: 400px;
  }
}
.show {
  width: 100%;
  position: relative;
  height: 1255px;
  //   margin-bottom: 117px;
  .one {
    width: 843px;
    height: 437px;
    background: #58a1e6;
    padding-top: 79px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    h2 {
      font-size: 66px;
      color: #fff;
      margin: 0 0 50px 79px;
    }
    p {
      width: 670px;
      font-size: 28px;
      // color: #ffbfa7;
      margin-left: 79px;
      line-height: 36px;
      color: #E9D1A3;
      letter-spacing: 1px;
    }
  }
  .two {
    width: 1236px;
    height: 770px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 161px;
    left: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .three {
    width: 835px;
    height: 557px;
    position: absolute;
    top: 682px;
    right: 17.8%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.how {
  width: 100%;
  height: 501px;
  background: rgba(0, 0, 0, 0.47);
  position: relative;
  background: url("../assets/image/SmartCampus/how.png") top center no-repeat;
  .details {
    text-align: center;
    color: #fff;
    letter-spacing: 3px;
    font-family: PingFang SC;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    h2 {
      font-size: 45px;
      //   font-weight: bold;
      margin-bottom: 19px;
    }
    p {
      font-size: 38px;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.Solution {
  position: relative;
  // height: 2483px;
  height: 1840px;
  padding: 98px 0 203px;
  background: url("../assets/image/SmartCampus/solution.png") no-repeat top
    center;
  .title {
    //   height: 55px;
    text-align: center;
    margin: 0 auto 259px;
    position: relative;
    width: 408px;
    h2 {
      font-size: 28px;
      color: #434343;
    }
    p {
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #cbcbcb;
    }
    .line {
      width: 47px;
      height: 3px;
      background: #345fc7;
      margin: 34px auto 0;
    }
  }
  .content {
    // overflow: hidden;
    .content_inner {
      position: absolute;
      width: 700px;
      height: 400px;
      background: rgba(255, 241, 241, 0.79);
      padding-top: 90px;
      box-sizing: border-box;
      // left: 11.5%;
      h2 {
        font-size: 30px;
        color: #404040;
        text-align: center;
      }
      p {
        font-size: 24px;
        color: #5d5d5d;
        line-height: 45px;
        width: 487px;
        margin: 53px auto 0;
      }
    }
    .one {
      right: 12.2%;
      top: 497px;
    }
    .two {
      left: 11.5%;
      top: 1018px;
    }
    .three {
      right: 12.2%;
      top: 1576px;
    }
    .four {
      left: 11.5%;
      top: 2133px;
    }
  }
}
</style>
