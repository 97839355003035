<template>
  <div class="customer" @click="CustServe">
    <!-- <a href="http://wpa.qq.com/msgrd?v=3&uin=1638872805&site=qq&menu=yes"> -->
    <i class="el-icon-chat-dot-square"></i>
    <span>QQ在线客服</span>
    <!-- </a> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    CustServe() {
      // window.open("http://wpa.qq.com/msgrd?v=3&uin=2929768308");
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=2929768308&site=qq&menu=yes"
        // "http://wpa.qq.com/msgrd?v=3&uin=1638872805&site=qq&menu=yes"
      );
    },
  },
};
</script>
<style lang='less' scoped>
.customer {
  font-size: 50px;
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 1000;
  color: #fff;
  // cursor: pointer;
  overflow: hidden;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  width: 60px;
  height: 64px;
  // position: absolute;
  // right: 0;
  background: rgba(53, 63, 88, 0.66);
  text-align: center;
  line-height: 64px;
  cursor: pointer;
  span {
    font-size: 20px;
    vertical-align: middle;
    // display: inline-block;
    margin-top: -15px;
    display: none;
  }
}
.customer:hover {
  width: 165px;
  background: #409eff;
  span {
    display: inline-block;
  }
}
</style>